import React from 'react';
import { NavLink } from 'react-router-dom';

const NavigationHeader = (props) => {
  const { children } = props;
  const toggle = () => {


    // var btn = document.getElementById("header-btn");
    var sidebar = document.getElementById("sidebar");
    var main = document.getElementsByClassName("main");
    if (document.body.contains(sidebar) && main && sidebar.classList.contains("collapse-nav")) {
      sidebar.classList.remove("collapse-nav");

      // main.classList.remove("collapse-nav");
      for (var i = 0; i < main.length; i++) {
        main[i].classList.remove("collapse-nav")
      }
    } else {
      sidebar.classList.add("collapse-nav");
      for (var i = 0; i < main.length; i++) {
        main[i].classList.add("collapse-nav")
      }
    }
  };
  return (
    <>
      <div class="d-flex align-items-center justify-content-between mb-2 mt-2 px-3 ">
        <NavLink to="/" className="logo d-flex align-items-center">
          <img src="assets/logos/logo.png" alt=""  width="160" height="200"/>
         
        </NavLink>
        <i class="bx bx-x toggle-sidebar-btn" onClick={() => toggle()}></i>
      </div>
    </>
  )
}
export default NavigationHeader;
