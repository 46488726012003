import React from 'react';
import { NavLink } from 'react-router-dom';

const Link = (props) => {
    const { path, icon, label } = props;
    return (
        <>
            <li class="nav-item p-0  my-1 ms-2">
                <NavLink className="nav-link rounded-2 p-1" to={path}>
                    <span className='nav-icon navigation d-flex me-2'>
                        <i class={"m-auto text-dark nav-link-icon "+ icon}></i>
                    </span>

                    <span>{label}</span>
                </NavLink>
            </li>
        </>
    )
}
export default Link;
