import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from "../../components/DataTable/DataTable";

import { saveAs } from 'file-saver';

import Filters from '../../components/Filters/Filters';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Select from '../../components/Select';
import { usePermissions } from '../../components/Authorization/Authorization';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
const LeaveApplications = ({id}) => {
    const { permissions } = useSelector(state => state.permissions);
    const [selectedOption, setSelectedOption] = useState(null);

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState("")
  
    const [hasPermissions] = usePermissions();
    const [state, setState] = useState({
      rowsPerPage: 10,
      data: [],
      currentPage: 1,
      totalPages: 0,
    });
    const [tableoptions, setTableoptions] = useState(
      [
        {
          column: 'Process',
          appearAs: 'Process',
          width: "60px",
  
          frozen: true,
          render: (object, value) => (
            <button disabled={!hasPermissions([19]) || object.leaveStatus == "Cancelled"} onClick={() => hasPermissions([19]) ? editHandler(object.id) : null} className='btn btn-sm btn-primary py-0 flex-grow-1 py-1'>
              <i class="fas fa-pen fa-md"></i>
            </button>
          ),
        },
        {
          column: 'Cancel',
          appearAs: 'Cancel',
          width: "80px",
  
          frozen: true,
          render: (object, value) => (
  <>
              {object.leaveStatus == "Cancelled" ?
                <>
                  <button disabled={true} className='btn btn-sm btn-danger py-0 flex-grow-1 py-1' >
                  <i class="fa-solid fa-xmark fa-xl"></i>
                  </button>
                </>
                :
                <>
                  {compareDate(getTodayDate(), "<", object.startDate) == true ? (
                    <>
                      <button disabled={!hasPermissions([20]) || !hasPermissions([19])} onClick={() => cancelLeave(object.id)} className='btn btn-sm btn-danger py-0 flex-grow-1 py-1' >
                      <i class="fa-solid fa-xmark fa-xl"></i>
                      </button>
                    </>
                  ) : (
                    <button disabled={true} className='btn btn-sm btn-danger py-0 flex-grow-1 py-1' >
                                <i class="fa-solid fa-xmark fa-xl"></i>
                    </button>
                  )}
                </>
              }
  </>
  
          ),
        },
        {
          column: 'employeeFullname',
          appearAs: 'Employee Fullname',
          width: '140px',
          frozen: true,
          render: null,
        },
        {
          column: 'leaveType',
          appearAs: 'Leave Type',
          width: '100px',
          frozen: false,
          render: null,
        },
        {
          column: 'startDate',
          appearAs: 'Start Date',
          width: '120px',
          frozen: false,
          render: null
        },
        {
          column: 'endDate',
          appearAs: 'End Date',
          width: '120px',
          frozen: false,
          render: null
        },
        {
          column: 'processedBy',
          appearAs: 'Processed By',
          width: '100px',
          frozen: false,
          render: null
        },
        {
          column: 'dateCreated',
          appearAs: 'Date Applied',
          width: '280px',
          frozen: false,
          render: null
        },
        {
          column: 'leaveStatus',
          appearAs: 'Leave Status',
          width: '80px',
          frozen: false,
          render: null,
        },
        {
          column: 'days',
          appearAs: 'Days Taken',
          width: '70px',
          frozen: false,
          render: null,
        }
      ])
    const filterItems = [
      { table: "statuses", column: "StatusId", label: "Status" },
      { table: "LeaveTypes", column: "LeaveTypeId", label: "Leave Type" },
      { table: "employeeleavecycles", column: "CycleId", label: "Cycle" }
    ]
  
    const fetchData = async () => {
      if (hasPermissions([18])) {
        await axios.post(`Employee/${id}/Leaves`, {
          page: state.currentPage,
          total: state.rowsPerPage,
          searchQuery
        })
          .then(res => {
            // res.data.data
            setState({ ...state, data: res.data.data, totalPages: res.data.totalPages })
          })
      }
    }
  
  
    const editHandler = (id) => {
      // setId(id)
      navigate("/leaves/"+id)
      // setShow(true);
    }
  
  
    const cancelLeave = (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#012970',
        cancelButtonColor: 'red',
        cancelButtonText: 'No, don\'t cancel it!',
        confirmButtonText: 'Yes, cancel it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.put("Leave/" + id)
          .then(res => {
            fetchData();
          })
          Swal.fire({
            icon: 'success',
            text: 'Successful!',
            confirmButtonColor: '#012970',
            confirmButtonText: 'Okay'
          });
        }
      })
     
    }
  
  
    const compareDate = (a, operation, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
  
      switch (operation) {
        case '==':
          return dateA.getTime() === dateB.getTime();
        case '===':
          return dateA.getTime() === dateB.getTime();
        case '!=':
          return dateA.getTime() !== dateB.getTime();
        case '!==':
          return dateA.getTime() !== dateB.getTime();
        case '>':
          return dateA > dateB;
        case '>=':
          return dateA >= dateB;
        case '<':
          return dateA < dateB;
        case '<=':
          return dateA <= dateB;
        default:
          console.log("ERROR");
          throw new Error('Invalid comparison operation');
      }
    };

    const download = (response) => {
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'file';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (filenameMatch) {
          filename = filenameMatch[1];
        }
      }
      const file = new Blob([response.data], { type: response.data.type });
      saveAs(file, filename)
  
    }
    const downloadFile = () => {
      axios.get("Leave/download", { responseType: 'blob' })
        .then(response => { download(response) });
    };
    const downloadExcel = () => {
      axios.get("Leave/downloadExcel", { responseType: 'blob' })
        .then(response => { download(response)  });
    };
  
    const importExcel = (e) => {
      e.preventDefault();
      const formdata = new FormData();
      formdata.append('file', e.target.files[0]);
      axios.post("Leave/import", formdata)
        .then(response => { fetchData() })
        .catch(() => { })
    };
  
    const createLeave = () => { navigate(`/employees/${id}/leaves/create`); }
    const handleSelect = (option) => {  setSelectedOption(null); };

    const getTodayDate = () => {
      var dateTime = new Date();
      return dateTime.toISOString().split("T")[0]
    }

    useEffect(() => {
      fetchData()
    }, [searchQuery,permissions])
    return (
        <>
            <section class="section animate__animated animate__fadeInLeft  zoom-900">
            <div class="row ">
          <div class="col-lg-12 my-3">
            <Filters table="Leaves" filterItems={filterItems} onApply={() => { fetchData() }} />
          </div>
        </div>
                <div class="row">
                    <div class="col-lg-12 ">
                        <div class="card card-border-tertiary ">
                            <div className='card-header border-0 mb-0 pb-0 d-flex justify-content-end'>
                                <h6 class='header-2'>LEAVES</h6>

                                <div class="ms-auto d-flex">
                                    <div class="search-bar  me-2">
                                        <form onSubmit={(e) => { e.preventDefault(); fetchData(); }} class="search-form d-flex align-items-center">
                                            <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} name="query" placeholder="Search" title="Enter search keyword" />
                                            <button type="submit" title="Search"><i class="bi bi-search"></i></button>
                                        </form>
                                    </div>
                                    {hasPermissions([18]) && <Select placeholder="Export File" onSelect={handleSelect} options={[
                                        { label: 'Export CSV', value: 'option1', render: () => { downloadExcel() } },
                                        { label: 'Export PDF', value: 'option3', render: () => { downloadFile() } },
                                    ]}
                                    />}
                                </div>
                                {/* <button onClick={() => { document.getElementById("import").click() }} className='btn btn-sm btn-primary  mx-2'>Import Excel</button> */}
                                {hasPermissions([17]) && <button onClick={(e) => { createLeave() }} className='btn btn-sm btn-primary  '>Apply Leave</button>}
                            </div>
                            <div className='card-body'>
                                <div className='' style={{ overflowX: "auto" }}>
                                    <DataTable
                                        backendPagination
                                        fetchData={() => fetchData()}
                                        className={'table table-striped table-bordereds '}
                                        state={state}
                                        setState={setState}
                                        tableOptions={tableoptions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default LeaveApplications;