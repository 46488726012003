import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from "../../components/DataTable/DataTable";
import ContentContainer from '../../components/Main Content/ContentContainer';
import Filters from '../../components/Filters/Filters';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Select from '../../components/Select';
import { usePermissions } from '../../components/Authorization/Authorization';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { compareDate, download, successMessage } from '../../components/utils';

const Leaves = () => {
  const { permissions } = useSelector(state => state.permissions);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("")
  const [hasPermissions] = usePermissions();
  const [state, setState] = useState({ rowsPerPage: 10, data: [], currentPage: 1, totalPages: 0 });

  const [tableoptions, setTableoptions] = useState(
    [
      {
        column: 'Process',
        appearAs: 'Process',
        width: "60px",

        frozen: true,
        render: (object, value) => (
          <button disabled={!hasPermissions([19]) || object.leaveStatus == "Cancelled"} onClick={() => hasPermissions([19]) ? editHandler(object.id) : null} className='btn btn-sm btn-primary py-0 flex-grow-1 py-1'>
            <i class="fas fa-pen fa-md"></i>
          </button>
        ),
      },
      {
        column: 'Cancel',
        appearAs: 'Cancel',
        width: "80px",

        frozen: true,
        render: (object, value) => (
          <>
            {object.leaveStatus == "Cancelled" ?
              <>
                <button disabled={true} className='btn btn-sm btn-danger py-0 flex-grow-1 py-1' >
                  <i class="fa-solid fa-xmark fa-xl"></i>
                </button>
              </>
              :
              <>
                {compareDate(getTodayDate(), "<", object.startDate) == true||true ? (
                  <>
                    <button disabled={!hasPermissions([20]) || !hasPermissions([19])} onClick={() => cancelLeave(object.id)} className='btn btn-sm btn-danger py-0 flex-grow-1 py-1' >
                      <i class="fa-solid fa-xmark fa-xl"></i>
                    </button>
                  </>
                ) : (
                  <button disabled={true} className='btn btn-sm btn-danger py-0 flex-grow-1 py-1' >
                    <i class="fa-solid fa-xmark fa-xl"></i>
                  </button>
                )}
              </>
            }
          </>

        ),
      },




      {
        column: 'employeeFullname',
        appearAs: 'Employee Fullname',
        width: '180px',
        frozen: true,
        render: null,
      },
      {
        column: 'leaveType',
        appearAs: 'Leave Type',
        width: '100px',
        frozen: false,
        render: null,
      },
      {
        column: 'startDate',
        appearAs: 'Start Date',
        width: '120px',
        frozen: false,
        render: null
      },
      {
        column: 'endDate',
        appearAs: 'End Date',
        width: '120px',
        frozen: false,
        render: null
      },
      {
        column: 'processedBy',
        appearAs: 'Processed By',
        width: '100px',
        frozen: false,
        render: null
      },
      {
        column: 'dateCreated',
        appearAs: 'Date Applied',
        width: '280px',
        frozen: false,
        render: null
      },
      {
        column: 'leaveStatus',
        appearAs: 'Leave Status',
        width: '90px',
        frozen: false,
        render: null,
      },
      {
        column: 'days',
        appearAs: 'Days Taken',
        width: '80px',
        frozen: false,
        render: null,
      },
      {
        column: 'attachmentUrl',
        appearAs: 'Attachment',
        width: "150px",
        frozen: false,
        render: (object, value) => (
          (object.leaveType == "Sick Leave" || object.leaveType == "Family Responsibility Leave") ?
            value ? <button onClick={() => downloadSickNote(value)} className='btn btn-sm btn-primary py-0 flex-grow-1 py-1 d-flex'>
              <i class="fas fa-download fa-sm pe-1 my-auto"></i>
              <span>  Download</span>
            </button> :
              <input onChange={uploadSickNote} id={object.id} name="attachment" type="file" className='w-100' />
            : <p className='mt-2 mb-1'>N/A</p>
        ),
      },
    ])
  const filterItems = [
    { table: "Statuses", column: "StatusId", label: "Status" },
    { table: "LeaveTypes", column: "LeaveTypeId", label: "Leave Type" },
    // { table: "employeeleavecycles", column: "CycleId", label: "Cycle" }
  ]

  const fetchData = async () => {
    if (hasPermissions([18])) {
      await axios.post("Leave", {
        page: state.currentPage,
        total: state.rowsPerPage,
        searchQuery
      })
        .then(res => {
          setState({ ...state, data: res.data.data, totalPages: res.data.totalPages })
        })
    }
  }


  const editHandler = (id) => { navigate("/leaves/" + id) }

  const cancelLeave = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#012970',
      cancelButtonColor: 'red',
      cancelButtonText: 'No, don\'t cancel it!',
      confirmButtonText: 'Yes, cancel it!'
    }).then(async (result) => {
      result.isConfirmed && await axios.put("Leave/" + id)
        .then(res => { fetchData(); Swal.fire(successMessage('Successful!')); })
    })

  }

  const downloadFile = () => {
    axios.get("Leave/download", { responseType: 'blob' })
      .then(response => { download(response) });
  };

  const downloadSickNote = (name) => {
    axios.get("Leave/leaveAttachment/" + name, { responseType: 'blob' })
      .then(response => { download(response) });
  };

  const downloadExcel = () => {
    axios.get("Leave/downloadExcel", { responseType: 'blob' })
      .then(response => {
        download(response)
      });
  };

  const importExcel = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append('file', e.target.files[0]);
    axios.post("Leave/import", formdata)
      .then(response => { fetchData(); })
      .catch(() => { })
      .finally(() => { });
  };

  const uploadSickNote = async (e) => {
    e.preventDefault();
    const { id } = e.target;
    const formdata = new FormData();
    formdata.append('attachment', e.target.files[0]);
    formdata.append('leaveId', id);
    await axios.post("Leave/UploadSickNote", formdata)
      .then(response => {
        setState((value) => {
          return {
            ...value, data: value.data.map((item) => {
              if (item.id == id) { item['attachmentUrl'] = response.data.data }
              return item;
            })
          }
        })
      })
      .catch(() => { })
  };

  const getTodayDate = () => { var dateTime = new Date(); return dateTime.toISOString().split("T")[0] }

  const createLeave = () => { navigate("/leaves/create"); }
  
  const handleSelect = (option) => {};

  useEffect(() => { fetchData() }, [searchQuery, permissions])
  return (
    <>
      <ToastContainer />
      <ContentContainer>

        <div class="row ">
          <div class="col-lg-12 my-3">
            <Filters table="Leaves" filterItems={filterItems} onApply={() => { fetchData() }} />
          </div>
        </div>

        <input class="d-none" type="file" id="import" onChange={importExcel} />
        <section class=" zoom-xs-50 section animate__animated animate__fadeInLeft">
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card card-border-tertiary ">
                <div className='card-header border-0 mb-0 pb-0 d-flex justify-content-end'>
                  <h6 class='header-2'>LEAVES</h6>

                  <div class="ms-auto d-flex">
                    <div class="search-bar  me-2">
                      <form onSubmit={(e) => { e.preventDefault(); fetchData(); }} class="search-form d-flex align-items-center" >
                        <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} name="query" placeholder="Search" title="Enter search keyword" />
                        <button type="submit" title="Search"><i class="bi bi-search"></i></button>
                      </form>
                    </div>
                    {hasPermissions([18]) && <Select placeholder="Export File" onSelect={handleSelect} options={[
                      { label: 'Export CSV', value: 'option1', render: () => { downloadExcel() } },
                      // { label: 'Export PDF', value: 'option3', render: () => { downloadFile() } },
                    ]}
                    />}
                  </div>
                  {/* <button onClick={() => { document.getElementById("import").click() }} className='btn btn-sm btn-primary  mx-2'>Import Excel</button> */}
                  {hasPermissions([17]) && <button onClick={(e) => { createLeave() }} className='btn btn-sm btn-primary  '>Apply Leave</button>}
                </div>
                <div className='card-body'>
                  <div className='' style={{ overflowX: "auto" }}>
                    <DataTable
                      backendPagination
                      fetchData={() => fetchData()}
                      className={'table table-striped table-bordereds '}
                      state={state}
                      setState={setState}
                      tableOptions={tableoptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContentContainer>
    </>
  )
}
export default Leaves;
