const BackendPagination = ({ totalPages, handlePageChange, currentPage, setState, state }) => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
            <li key={i} className="page-item" onClick={() => { setState({ ...state, currentPage: i }) }}><a class={`rounded-2 page-link page-number  ${currentPage === i ? "active-page bg-primary" : "text-dark"}`} >  {i}</a></li>
        );
    }
    return (
        <>
            <ul class="pagination pagination-sm m-0 text-dark">

                <li className="page-item me-1" onClick={() => state.currentPage > 1 ? setState({ ...state, currentPage: state.currentPage - 1 }) : null} disabled={currentPage === 1}>
                    <a class="page-link rounded-2" >
                        <i class="fa-solid fa-angle-left">

                        </i>
                    </a>
                </li>

                {pageButtons}

                <li className="page-item " onClick={() => setState({ ...state, currentPage: state.currentPage === totalPages ? state.currentPage : state.currentPage + 1 })} disabled={state.currentPage === pageButtons.length}>
                    <a class="page-link rounded-2"  >
                        <i class="fa-solid fa-angle-right">

                        </i>
                    </a>
                </li>

            </ul>
            <div className="ms-3 d-flex align-items-center text-dark records-total mb-3">Page {currentPage} of {totalPages}</div>
        </>
    )
}

export default BackendPagination;