import React, { useEffect, useState } from 'react';
import ContentContainer from '../../components/Main Content/ContentContainer';
import axios from 'axios';
import UserForm from './UserForm';
import UserPasswordForm from './UserPasswordForm';
import { usePermissions } from '../../components/Authorization/Authorization';
import SubHeader from '../../components/Headers/SubHeader/SubHeader';

const Profile = () => {
    const [roles, setRoles] = useState([]);
    const [hasPermissions] = usePermissions();
    const [imageSrc, setImageSrc] = useState('');

    const downloadImage = async (fileName) => {
        try {
            const response = await axios.get(`User/image`, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'image/jpeg' });
            const url = URL.createObjectURL(blob);
            setImageSrc(url);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => { downloadImage(); }, [])
    return (
        <>
            <ContentContainer>
                <SubHeader icon={"bx bxs-user"} label="Account Information" previousPage={"/"} />
                {hasPermissions([46]) && (
                    <section class="section ">
                        <div class="row">
                            <div class="col-md-3 mt-md-0   mx-auto">
                                <div class="card">
                                    <div class="card-body p-3">
                                        <UserPasswordForm />
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-9 mt-md-0 mt-2 animate__animated animate__fadeInLeft mx-auto">
                                <div class="card">
                                    <div class="card-body p-3">
                                        <UserForm />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </ContentContainer>
        </>
    )
}
export default Profile;

