import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [show, setShow] = useState(false);
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();

    const login = (formData) => async dispatch => {
        dispatch({ type: 'LOGIN_REQUEST', payload: formData });
        await axios.post(`auth/login`, formData)
            .then(res => { 
                // dispatch({ type: 'LOGIN_SUCCESS', payload: "dsf" }); localStorage.setItem('userInfo', (JSON.stringify("dsf" )));

                dispatch({ type: 'LOGIN_SUCCESS', payload: res.data.data }); localStorage.setItem('userInfo', (JSON.stringify(res.data.data)));
            
            })
            .catch(err => { dispatch({ type: 'LOGIN_FAIL', error: err.response.message }); setShow(true); })
    }
    const submit = (e) => {
        e.preventDefault();
        dispatch(login({ email, password }));
    }
    return (
        <>
            <div class="container">
                <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4 ">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-9 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                <div class="card mb-3 card-border-tertiary animate__animated animate__fadeInDown">
                                    <div class="card-body p-0">
                                        <div class="row g-0 ">
                                            <div class="col-lg-6">
                                                <div class="card-body p-md-5 px-md-3 mx-md-0 px-2 mx-0">
                                                    <div class="d-flex justify-content-center py-4">
                                                        <a href="index.html" class="logo d-flex align-items-center w-auto">
                                                            <img src="assets/logos/logo.png" alt="" width="160" height="200" />
                                                        </a>
                                                    </div>

                                                    {show && <div class="alert alert-danger py-1 rounded-1 text-center" role="alert">
                                                        Incorrect Password Or Email
                                                    </div>}
                                                    <form class="row g-3 needs-validation mb-2 mx-1" onSubmit={submit}>

                                                        <div class="col-12">
                                                            <label for="yourUsername" class="form-label">Username</label>
                                                            <div class="input-group has-validation">
                                                                <input value={email} placeholder='Enter Email' required onChange={e => { setShow(false); setEmail(e.target.value) }} type="email" name="username" class="form-control" id="yourUsername" />
                                                                <div class="invalid-feedback">Please enter your username.</div>
                                                            </div>
                                                        </div>

                                                        <div class="col-12">
                                                            <label for="yourPassword" class="form-label">Password</label>
                                                            <input required placeholder='Enter Password' value={password} onChange={e => { setShow(false); setPassword(e.target.value) }} type="password" name="password" class="form-control" id="yourPassword" />
                                                            <div class="invalid-feedback">Please enter your password!</div>
                                                        </div>

                                                        <div class="col-12">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                                                <label class="form-check-label" for="rememberMe">Remember me</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <button class="btn btn-lg bg-primary text-light w-100" type="submit">Login</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex align-items-center bg-primary rounded-2 login-banner">
                                                <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                                                    <h4 class="mb-4">We are more than just a company</h4>
                                                    <p class="small mb-0">Unlocking a world of limitless potential
                                                        through cutting-edge software solutions. With a relentless passion
                                                        for innovation, we shape the future of technology, one line of code at a time.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default SignIn;
