import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable/DataTable';
import { Form, Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
// import Filters from '../../components/Filters/Filters';
import ContentContainer from '../../components/Main Content/ContentContainer';
import Filters from '../../components/Filters/Filters';
import { baseUrl } from '../../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Steps from '../../components/Steps/Steps';
import SelectorModal from '../../components/modals/SelectorModal';
import SubHeader from '../../components/Headers/SubHeader/SubHeader';
import Swal from 'sweetalert2';
import { errorMessage, infoMessage, sanitize, successMessage, testRegex } from '../../components/utils';
const TempUserForm = ({ onModalClose }) => {
  const [id, setId] = useState(useParams().id)
  const [roles, setRoles] = useState([]);
  const [genders, setGenders] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [races, setRaces] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [managers, setManagers] = useState([]);
  const [loadRoles, setLoadRoles] = useState(false)
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const location = useLocation();

  const [employee, setEmployee] = useState({
    genderId: null,
    dateOfBirth: null,
    countryId: null,
    raceId: null,
    address: null,
    contactNumber: null,
    jobTitleId: null,
    employeeTypeId: null,
    startDate: null,
    endDate: null,
    managerId: null,
    nextOfKin: [],
  });

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    testRegex(value, /^[a-zA-Z\d@,.\s\/-]*$/) && setEmployee({ ...employee, [name]: value })

  }

  const onChangeUser = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    testRegex(value, /^[a-zA-Z\d@,.\s\/-]*$/) && setUser({ ...user, [name]: value })
  }

  const getRoles = async () => {
    await axios.get("Roles/all")
      .then(res => { setRoles(res.data.data); })
      .catch(() => { })
  }

  const getManagers = async () => {
    await axios.get("Employee/managers")
      .then(res => { setManagers(res.data.data) })
      .catch(() => { })
  }

  const addHandler = () => {
    const employeeProfile = selectedRoles.includes(3) || selectedRoles.includes(4);
    axios.post("User/add", { ...user, roles: selectedRoles, employee: employeeProfile ? employee : null })
      .then(res => {
        navigate("/users");
        Swal.fire(successMessage('successful!'));
      }).catch(error => {
        Swal.fire(errorMessage(error?.response?.data?.message));
      })
  }

  const getGenders = () => {
    axios.get("genders")
      .then(response => {
        setGenders(response.data.data)
      })
  }

  const getEmployeeTypes = () => {
    axios.get("employeetypes")
      .then(response => {
        setEmployeeTypes(response.data.data)
      })
  }

  const getRaces = () => {
    axios.get("races")
      .then(response => { setRaces(response.data.data) })
      .catch(() => { })

  }

  const getJobtitles = () => {
    axios.get("jobtitles")
      .then(response => { setJobTitles(response.data.data) })
      .catch(() => { })
  }

  const getCountries = () => {
    axios.get("countries")
      .then(response => { setCountries(response.data.data) })
      .catch(() => { })
  }

  const getUserStatuses = () => {
    axios.get("status/users-statuses")
      .then(response => { setStatuses(response.data.data) })
      .catch(() => { })
  }

  const updateHandler = (e) => {
    const employeeProfile = selectedRoles.includes(3) || selectedRoles.includes(4);
    axios.put("User/" + user.id, { ...user, roles: selectedRoles, employee: employeeProfile ? employee : null })
      .then(res => {
        navigate("/users");
        Swal.fire(successMessage("Successful!"));
      }).catch(() => {
        Swal.fire(errorMessage('Unsuccessful!'));
      })
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedRoles.length != 0)
      id ? updateHandler() : addHandler();
    else
      Swal.fire(infoMessage("A user should have at least one role"))
  }
  const onCheckRole = (id) => {
    var ids = selectedRoles;
    if (ids.includes(id)) { ids = ids.filter(item => item != id); setSelectedRoles(ids); }
    else { setSelectedRoles(ids.concat([id])); }

  }


  const fetchData = async () => {
    await axios.get('User/' + id)
      .then(res => {
        setUser(res.data.data)
        if (res.data.data.employee)
          setEmployee(res.data.data.employee)
        setLoadRoles(true)
      })
      .catch(() => { })
  }

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    // Only allow numeric characters
    if (!/^[0-9]+$/.test(keyValue)) {
      e.preventDefault();
    }
  };
  const cancel = () => { location.pathname.includes("employees")? navigate("/employees"): navigate("/users"); }
  //watch selected role
  useEffect(() => {
    if (user && user.roles) {
      var ids = []
      user.roles.forEach((item) => { ids.push(item.id); })
      setSelectedRoles(ids);
    }
  }, [loadRoles])

  useEffect(() => { id && fetchData(); }, [id])

  useEffect(() => { window.history.state == null && navigate("/users"); }, [])

  useEffect(() => {
    getRoles();
    getGenders();
    getCountries();
    getEmployeeTypes();
    getRaces();
    getJobtitles();
    getManagers();
  }, [])

  useEffect(() => { getUserStatuses() }, [])
  return (
    <>
      <ToastContainer />
      <ContentContainer>

        <SubHeader label="Users form" previousPage={"/users"} />

        <section class="section animate__animated animate__fadeInLeft  zoom-900">
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card card-border-tertiary ">
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-12 mb-2 mx-auto '>
                      <div class="pt-0 " id="">
                        <form onSubmit={onSubmit}>
                          <>

                            <div className='row'>
                              <div className='col-lg-8 col-md-10 col-sm-12 mb-2'>
                                <div className='row'>
                                  <div className='col-lg-6 mb-2'>
                                    <div class="row mb-1">
                                      <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Firstname</label>
                                      <div class="col-md-12 col-lg-12">
                                        <input required onChange={onChangeUser} value={sanitize(user.firstname)} name="firstname" type="text" class="form-control" placeholder='Enter your firstname here...' />
                                      </div>
                                    </div>
                                  </div>

                                  <div className='col-lg-6 mb-2'>
                                    <div class="row mb-1">
                                      <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Lastname</label>
                                      <div class="col-md-12 col-lg-12">
                                        <input required onChange={onChangeUser} value={sanitize(user.lastname)} name="lastname" type="text" class="form-control" placeholder='Enter your lastname here...' />
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className='col-lg-6 mb-2'>
                                    <div class="row mb-1">
                                      <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Cellphone(10 digits)</label>
                                      <div class="col-md-12 col-lg-12">
                                        <input required onChange={(e) => { e.target.value.length <= 10 && onChangeUser(e) }} value={sanitize(user.cellphone)} name="cellphone" type="text" pattern="[0-9]{10}" class="form-control" placeholder='E.g 0768776644' onClick={(e) => e.target.addEventListener('keypress', handleKeyPress)} />
                                      </div>
                                    </div>
                                  </div> */}

                                  <div className='col-lg-6 mb-2'>
                                    <div class="row mb-1">
                                      <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Email</label>
                                      <div class="col-md-12 col-lg-12">
                                        <input required onChange={onChangeUser} value={sanitize(user.email)} name="email" type="Email" class="form-control" placeholder='Enter your email here...' />
                                      </div>
                                    </div>
                                  </div>

                                  <div className='col-lg-6 mb-2'>
                                    <div class="row mb-1">
                                      <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Status</label>
                                      <div class="col-md-12 col-lg-12">
                                        <select required onChange={onChangeUser} value={user.statusId} name="statusId" class="form-select" >
                                          <option value="">Please select</option>
                                          {statuses.map((item, key) => (
                                            <>
                                              <option value={item.id}>{sanitize(item.name)}</option>
                                            </>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-4 col-md-10 col-sm-12'>
                                <div className='row'>
                                  <div className='col-12 mb-2'>
                                    <label className='' style={{ fontWeight: "600" }}>Assign User Roles</label>
                                  </div>
                                  <div className='col-12 d-flex flex-column'>
                                    {roles.map((item, key) => (
                                      <>
                                        <div class="d-flex  me-4">
                                          <input onChange={() => onCheckRole(item.id)} checked={selectedRoles.includes(item.id)} type="checkbox" />
                                          <p className='ms-2 label p-0 m-0'>{sanitize(item.name)}</p>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </div>

                              </div>
                              <hr className='my-1 mt-2' />
                              <div className='row'>
                                <div className='col-12'>
                                  <p className='text-danger'>Note:All passwords are generated by the system and sent to the provided email.</p>
                                </div>
                              </div>
                              {(selectedRoles.includes(1) || selectedRoles.includes(2))
                                && !(selectedRoles.includes(3) || selectedRoles.includes(4))
                                && (
                                  <div className='d-flex '>
                                    <button type="button" onClick={cancel} className='btn btn-sm bg-danger text-light me-2 '>Cancel</button>
                                    <button type='submit' className='btn btn-sm bg-primary text-light ms-auto'>Submit</button>
                                  </div>
                                )}

                            </div>
                          </>
                          {
                            (selectedRoles.includes(3) || selectedRoles.includes(4)) && (
                              <>
                                <div className='row'>
                                  <div className='col-lg-12 col-md mb-2'>

                                    <div className='row'>
                                      <div className='col-lg-8 col-md-12 col-sm-12'>
                                        <>
                                          <div className='row'>
                                            <div className='col-lg-6 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Race</label>
                                                <div class="col-md-12 col-lg-12">
                                                  <select required onChange={onChange} value={employee.raceId} name="raceId" class="form-select" >
                                                    <option value="">Please select</option>
                                                    {races.map((item, key) => (
                                                      <>
                                                        <option value={item.id}>{sanitize(item.name)}</option>
                                                      </>
                                                    ))}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>

                                            <div className='col-lg-6 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Gender</label>
                                                <div class="col-md-12 col-lg-12">
                                                  <select required onChange={onChange} value={employee.genderId} name="genderId" class="form-select" >
                                                    <option value="">Please select</option>
                                                    {genders.map((item, key) => (
                                                      <>
                                                        <option value={item.id}>{sanitize(item.name)}</option>
                                                      </>
                                                    ))}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='col-lg-6 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Employee Type</label>
                                                <div class="col-md-12 col-lg-12">
                                                  <select required onChange={onChange} value={sanitize(employee.employeeTypeId)} name="employeeTypeId" class="form-select" >
                                                    <option value="">Please select</option>
                                                    {employeeTypes.map((item, key) => (
                                                      <>
                                                        <option value={item.id}>{item.name}</option>
                                                      </>
                                                    ))}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='col-lg-6 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Job Title </label>
                                                <div class="col-md-12 col-lg-12">
                                                  <select required onChange={onChange} value={employee.jobTitleId} name="jobTitleId" class="form-select" >
                                                    <option value="">Please select</option>
                                                    {jobTitles.map((item, key) => (
                                                      <>
                                                        <option value={item.id}>{sanitize(item.name)}</option>
                                                      </>
                                                    ))}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>

                                            <div className='col-lg-6 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Manager</label>
                                                <div class="col-md-12 col-lg-12">

                                                  <select onChange={onChange} value={employee.managerId} name="managerId" class="form-select" >
                                                    <option value="">Please select</option>
                                                    {managers.map((item, key) => (
                                                      <>
                                                        <option value={item.id}>{sanitize(item.name)}</option>
                                                      </>
                                                    ))}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>

                                            <div className='col-lg-6 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Date Of Birth</label>
                                                <div class="col-md-12 col-lg-12">
                                                  <input required onChange={onChange} value={sanitize(employee.dateOfBirth)} name="dateOfBirth" type="date" class="form-control" />
                                                </div>
                                              </div>
                                            </div>
                                            <div className='col-lg-6 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Start Date</label>
                                                <div class="col-md-12 col-lg-12">
                                                  <input required onChange={onChange} value={sanitize(employee.startDate)} name="startDate" type="date" class="form-control" />
                                                </div>
                                              </div>
                                            </div>
                                            <div className='col-lg-6 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">End Date</label>
                                                <div class="col-md-12 col-lg-12">
                                                  <input disabled={employee.employeeTypeId == 2} required={!employee.employeeTypeId == 2} onChange={onChange} value={sanitize(employee.endDate)} name="endDate" type="date" class="form-control" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <hr className='my-1 my-3' />
                                        </>
                                        <>
                                          <div className='row'>
                                            <div className='col-lg-6 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Country</label>
                                                <div class="col-md-12 col-lg-12">
                                                  <select required onChange={onChange} value={employee.countryId} name="countryId" class="form-select" >
                                                    <option value="">Please select</option>
                                                    {countries.map((item, key) => (
                                                      <>
                                                        <option value={item.id}>{sanitize(item.name)}</option>
                                                      </>
                                                    ))}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='col-lg-6 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Cellphone(10 digits)</label>
                                                <div class="col-md-12 col-lg-12">
                                                  <input required onChange={(e) => { e.target.value.length <= 10 && onChange(e) }} value={sanitize(employee.contactNumber)} name="contactNumber" type="text" class="form-control" placeholder='E.g 0768776644' onClick={(e) => { e.preventDefault(); e.target.addEventListener('keypress', handleKeyPress) }} />

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='row'>
                                            <div className='col-lg-12 mb-2'>
                                              <div class="row mb-1">
                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Address</label>
                                                <div class="col-md-12 col-lg-12">
                                                  <textarea rows={5} required onChange={onChange} value={sanitize(employee.address)} name="address" type="text" class="border w-100 rounded-1" placeholder='Enter  Address here...'></textarea>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <hr className='my-1 mt-2' />
                                        </>
                                      </div>
                                    </div>
                                    <div className='d-flex '>
                                      <button type="button" onClick={cancel} className='btn btn-sm bg-danger text-light me-2 '>Cancel</button>
                                      <button type='submit' className='btn btn-sm bg-primary text-light ms-auto'>Submit</button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


      </ContentContainer>
    </>
  )
}
export default TempUserForm;
