const RecentLeaves = ({ data = [], height = "268px", showApplicant = true }) => {
    return (
        <>
            <div class="card px-0" style={{ overflowY: "auto" }}>
                <h6 class="text-start mx-3 py-2 bg-light rounded-1 mt-1 ps-2 header-3">Recent Leave Applications</h6>
                <div class="card-body px-2 py-0" style={{ height: height }}>

                    <div class="tab-content pt-0" id="borderedTabJustifiedContent">
                        <div class="tab-pane fade active show" id="bordered-justified-home" role="tabpanel" aria-labelledby="home-tab">
                            <table class="table text-dark">
                                <thead>
                                    <tr>
                                        {showApplicant && <th scope="col">Applicant</th>}
                                        <th scope="col">Start Date</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, key) => (<>
                                        <tr key={key}>
                                            {showApplicant && <td>{item.employeeFullname}</td>}
                                            <td>{item.startDate.split("T")[0]}</td>
                                            <td>{item.endDate.split("T")[0]}</td>
                                            <td><span class={`badge  ${item.status === "Pending" ? "bg-primary" : item.status === "Approved" ? "bg-secondary" : item.status === "Rejected" || item.status === "Cancelled" ? "bg-danger" : ""} rounded-1 w-100`}>{item.leaveStatus}</span></td>
                                        </tr>
                                    </>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecentLeaves;