import CountUp from "react-countup";
import { sanitize } from "../utils";

const SquareWidget = ({ className, value, label, icon, bgColor }) => {

    return (
        <>
            <div class={className}>
                <div class="card h-100 mb-2 p-0">
                    <div class="card-body pb-0 mb-0 text-center d-flex p-2">
                        <div className="d-flex justify-content-center mb-2"><div className={`widget-icon rounded-1 d-flex text-light  ${bgColor}-1 bg-primary`}><i className={`icon ${icon} m-auto `}></i></div></div>
                        <div className=" text-end ms-auto">
                            <p class="mb-0 mb-1">{sanitize(label)}</p>
                            {/* <CountUp start={0} end={value} delay={1}> */}
                            {/* {({ countUpRef }) => ( */}
                            <p class="text-gradient m-0 "><span className="header-1 text-primary" id="status1"  >{sanitize(value)}</span> <span class=" ms-n2">days</span></p>
                            {/* )} */}
                            {/* </CountUp> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SquareWidget;