import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Swal from 'sweetalert2';
import {decode} from 'html-entities';
import { errorMessage, sanitize, successMessage } from '../../components/utils';

const EmailTemplateForm = ({ id, onModalClose }) => {
  const [loading, setLoading] = useState(false);
  const [components, setComponents] = useState([]);
  const navigate = useNavigate();
  const [emailTemplate, setEmailTemplate] = useState({ });

  const onChange = (e) => {
    const { value, name } = e.target;
    setEmailTemplate(prevEmailTemplate => ({ ...prevEmailTemplate, [name]: value }));
  }

  const getEmailTemplateById = async () => {
    await axios.get("EmailTemplates/" + id)
      .then(res => { setEmailTemplate(res.data.data) })
      .catch(() => { Swal.fire(errorMessage('Unsuccessful!')); })
  }

  const updateHandler = () => {
    axios.put("EmailTemplates/" + id, emailTemplate)
      .then(res => { setLoading(false); navigate("/email-management"); Swal.fire(successMessage("Successful!")); })
      .catch(() => { Swal.fire(errorMessage('Unsuccessful!')); })
  }

  const onSubmit = (e) => { e.preventDefault(); id && updateHandler(); }

  const getComponents = async () => {
    await axios.get("access-control/GetComponents")
      .then(response => { setComponents(response.data.data) })
      .catch(() => { })
  }

  useEffect(() => { getComponents(); getEmailTemplateById(); }, [id])
  return (
    <>
      <form onSubmit={onSubmit} encType='multipart/form-part'>
        <>
          <div className='row'>
            <div className='col-lg-6 mb-2'>
              <div className='row'>
                <div className='col-lg-12 mb-2'>
                  <div class=" mb-1">
                    <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Name</label>
                    <div class="col-md-12 col-lg-12">
                      <input required onChange={onChange} value={sanitize(emailTemplate.name)} name="name" type="text" class="form-control" placeholder='Enter start date here...' />
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 mb-2'>
                  <div class=" mb-1">
                    <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Subject</label>
                    <div class="col-md-12 col-lg-12">
                      <input required onChange={onChange} value={sanitize(emailTemplate.subject)} name="subject" type="text" class="form-control" placeholder='Enter start date here...' />
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 mb-2'>
                  <div class=" mb-1">
                    <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Subject</label>
                    <div class="col-md-12 col-lg-12">
                      <input required onChange={onChange} value={sanitize(emailTemplate.placeholders)} name="placeholders" type="text" class="form-control" placeholder='Enter start date here...' />
                    </div>
                  </div>
                </div>

                <div className='col-lg-12 mb-2'>
                  <div class=" mb-1">
                    <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Component</label>
                    <div class="col-md-12 col-lg-12">
                      <select required onChange={onChange} value={emailTemplate.componentId} name="leaveTypeId" class="form-control" >
                        <option value="">Please select</option>
                        {components.map((item, key) => (
                          <>
                            <option value={item.id}>{sanitize(item.name)}</option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 mb-2'>
                  <div class="row mb-1">
                    <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Description</label>
                    <div class="col-md-12 col-lg-12">
                      <textarea required onChange={onChange} value={sanitize(emailTemplate.description)} name="subject" type="text" class="border w-100 form-control" rows={2} placeholder='Enter description here...' >

                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='col-lg-6 mb-2'>
              <div class="row mb-1">
                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Email Content</label>
                <div class="col-md-12 col-lg-12">

                  <CKEditor

                    editor={Editor}
                    data={emailTemplate.htmlContent ? sanitize(decode(emailTemplate.htmlContent)) : ""}
                    onChange={(event, editor) => {
                      const object = {

                        target: {
                          id: 'htmlContent',
                          name: 'htmlContent',
                          value: editor.getData(),
                          type: 'ck-editor'
                        }
                      }
                      onChange(object)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
        <div className='d-flex '>
          <button type="button" onClick={() => navigate("/email-management")} className='btn btn-sm btn-warning text-light me-2'>Cancel</button>
          <button disabled={loading} type='submit' className='btn btn-sm btn-primary text-light ms-auto'>
            <Loader loading={loading} />
            Submit
          </button>
        </div>
      </form>

    </>
  )
}
export default EmailTemplateForm;


