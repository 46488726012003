const Calender = ({ calender, nextMonth = () => { }, previousMonth = () => { } }) => {
  return (
    <>
      <div class="card card-calendar">
        <div class="card-body pt-0" style={{ height: "335px" }}>
          <div class="calendar fc fc-media-screen fc-direction-ltr fc-theme-standard" data-bs-toggle="calendar" id="calendar">
            <div class="fc-header-toolbar fc-toolbar ">
              <div class="fc-toolbar-chunk">
                <h6 class="text-start mx-3 py-2  rounded-1 mt-1">{calender.monthName} {calender.year}</h6>
              </div>
              <div class="fc-toolbar-chunk"></div>
              <div class="fc-toolbar-chunk">
                {/* <button class="fc-today-button fc-button fc-button-primary" type="button">today</button> */}
                <div class="fc-button-group">
                  <button onClick={() => previousMonth()} class="fc-prev-button fc-button fc-button-primary" type="button" aria-label="prev">
                    <i class="bi bi-caret-left-fill"></i></button>
                  <button onClick={() => nextMonth()} class="fc-next-button fc-button fc-button-primary" type="button" aria-label="next"><i class="bi bi-caret-right-fill"></i></button>
                </div>
              </div>
            </div>
            <div class="fc-view-harness fc-view-harness-passive">
              <div class="fc-daygrid fc-dayGridMonth-view fc-view">
                <table class="fc-scrollgrid overflow-x">
                  <thead>
                    <tr class="fc-scrollgrid-section fc-scrollgrid-section-header ">
                      <td>
                        <div class="fc-scroller-harness">
                          <div class="fc-scroller" >
                            <table class="fc-col-header " style={{ fontSize: "80px" }}>
                              <tbody>
                                <tr>
                                  <th class="fc-col-header-cell fc-day fc-day-sun col">
                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion text-danger ">Sun</a></div>
                                  </th>
                                  <th class="fc-col-header-cell fc-day fc-day-mon col">
                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Mon</a></div>
                                  </th>
                                  <th class="fc-col-header-cell fc-day fc-day-tue col">
                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Tue</a></div>
                                  </th>
                                  <th class="fc-col-header-cell fc-day fc-day-wed col">
                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Wed</a></div>
                                  </th>
                                  <th class="fc-col-header-cell fc-day fc-day-thu col">
                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Thu</a></div>
                                  </th>
                                  <th class="fc-col-header-cell fc-day fc-day-fri col">
                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion ">Fri</a></div>
                                  </th>
                                  <th class="fc-col-header-cell fc-day fc-day-sat col">
                                    <div class="fc-scrollgrid-sync-inner"><a class="fc-col-header-cell-cushion text-danger">Sat</a></div>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="fc-scrollgrid-section fc-scrollgrid-section-body ">
                      <td>
                        <div class="fc-scroller-harness">
                          <div class="fc-scroller" style={{ overflow: "visible" }}>
                            <div class="fc-daygrid-body fc-daygrid-body-unbalanced fc-daygrid-body-natural" >
                              <table class="fc-scrollgrid-sync-table">
                                <colgroup></colgroup>
                                <tbody>
                                  {calender.data.map((item, key) => (
                                    <>
                                      <tr key={key}>
                                        {item.map((element, index) => (
                                          <td key={100 * index} class={`fc-daygrid-day fc-day fc-day-sun fc-day-past  ${element?.isToday ? "bg-light" : ""}  ${index == 0 || index == 6 ? "text-danger" : ""} `} data-date="2020-11-01">
                                            <div class="cell-height">
                                              <div class="fc-daygrid-day-top my-0"><p class={`fc-daygrid-day-number my-0 p-0 ${index == 0 || index == 6 ? "text-danger" : ""}`}>{element?.day}</p></div>


                                              {element && element?.holiday && element && element?.onLeave && false ? (
                                                <>
                                                  
                                                </>
                                              ) : element && (element?.holiday || element?.onLeave) ? (
                                                <>
                                                  {element?.holiday && (
                                                    <>
                                                      <a class="fc-daygrid-event fc-daygrid-block-event fc-h-event fc-event fc-event-draggable fc-event-resizable fc-event-start fc-event-end fc-event-past bg-primary">
                                                        <div class="fc-event-main">
                                                          <div class="fc-event-main-frame">
                                                            <div class="fc-event-title-container text-center">
                                                              <div class="fc-event-title fc-sticky">{element?.holiday.name}</div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="fc-event-resizer fc-event-resizer-end"></div>
                                                      </a>
                                                    </>
                                                  )}
                                                  {element?.onLeave && (
                                                    <>
                                                      <a class="fc-daygrid-event fc-daygrid-block-event fc-h-event fc-event fc-event-draggable fc-event-resizable fc-event-start fc-event-end fc-event-past bg-secondary">
                                                        <div class="fc-event-main">
                                                          <div class="fc-event-main-frame">
                                                            <div class="fc-event-title-container text-center">
                                                              <div class="fc-event-title fc-sticky text-center ">On Leave</div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="fc-event-resizer fc-event-resizer-end"></div>
                                                      </a>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <div class="fc-daygrid-day-events"></div>
                                                  <div class="fc-daygrid-day-bg my-2"></div>
                                                </>
                                              )}
                                            </div>
                                          </td>
                                        ))}
                                      </tr>
                                    </>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Calender;