import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LeaveTypesConfigurations = ({ data, setData }) => {
  const [leaveTypes, setLeaveTypes] = useState([]);
  const navigate = useNavigate();

  const getLeaveTypes = async () => {
    await axios.get("leavetypes")
      .then(res => { setLeaveTypes(res.data.data); })
      .catch(()=>{ })
  }
  
  useEffect(() => { getLeaveTypes(); }, [])
  return (
    <div class=" w-100">
      <div className='row'>
        <div className='col-sm-12 col-md-8 col-lg-12'>
          <table class="table table-striped">
            <thead class="bg-primary text-light">
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Total Days</th>
                <th>Notice Period</th>
                <th>Supporting document</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {leaveTypes.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.totalDays} days</td>
                  <td>{item.noticePeriodDays} Days</td>
                  <td>{item.supportingDocument? "Required":"Not Required"}</td>
                  <td className='py-0' style={{ width: "40px" }}>
                    <button onClick={() => navigate("/configurations/leave-types/" + item.id)} className="btn btn-sm btn-primary my-1 w-100">
                      <i class="fas fa-pen fa-md"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LeaveTypesConfigurations;
