import { useSelector } from "react-redux"


export const ProtectedRoutes = ({ element }) => {
    const { token } = useSelector(state => state.userInfo.userInfo);
    const { navigation } = useSelector(state => state.navigation);
    if (!token) {
        window.location = "/signin"
    } else {

        return element;

        for (let i = 0; i < navigation.length; i++) {

            if (window.location.pathname === navigation[i].link ||window.location.pathname==="/xyz")
                return element;
            //    console.log({ name: window.location.pathname, names: navigation[i].link });
        }
        if(navigation.length>0){
            window.location = navigation[0].link 
        }
    }

}