import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import SubHeader from '../../components/Headers/SubHeader/SubHeader';
import { errorMessage, successMessage } from "../../components/utils";
import Swal from 'sweetalert2';
const EmployeeReward = ({ id }) => {
    const [data, setData] = useState([]);
    const [bonusLeaveDays, setBonusLeaveDays] = useState(0);

    const [active, setActive] = useState("");

    const fetchData = async () => {
        await axios.post('EmployeeLeaveCycle/GetEmployeeLeaveCycles/' + id, {})
            .then(res => { setData(res.data.data) })
            .catch(err => { })
    }

    const updateEmployeeLeaveCycleById = async (elcId) => {
        await axios.post('EmployeeLeaveCycle/UpdateEmployeeLeaveCycleById/' + elcId, { bonusLeaveDays })
            .then(res => { fetchData(); Swal.fire(successMessage("An email has been sent notifying the employee"));})
            .catch(err =>  Swal.fire(errorMessage("An error was encountered")))
    }

    useEffect(() => {
        id && fetchData()
    }, [id])

    return (
        <>

            <SubHeader label="Leave Cycles" previousPage={"/employees"} />
            <section class="section animate__animated animate__fadeInLeft  zoom-900">
                <div class="row mb-3">
                    {data.map((item) => (<>
                        <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                            <div class="card mb-2 p-0 py-1 pt-1 h-100">
                                <div class="card-body pb-0 mb-0  p-2">
                                    <div className='text-center d-flex'>
                                        <div className="d-flex justify-content-center mb-2"><div className={`widget-icon rounded-1 d-flex text-light  ${"bgColor"}-1 bg-primary`}><i className={`icon fa-solid fa-arrows-spin  m-auto `}></i></div></div>
                                        <div className=" text-end ms-auto">
                                            <p class="header-2 mb-0 mb-0">{item.name}</p>
                                            <p class="mb-0 mb-1">{item.startDate + " - " + item.endDate}</p>
                                        </div>
                                    </div>
                                    <p class="text-gradient m-0 d-flex">
                                        <div className='flex-grow-1'>
                                            <span className="header-1 text-primary ms-auto me-1 mt-2 " id="status1"  >+{item.bonusLeaveDays}</span> <span class=" ms-n2 mt-2"> Bonus Leave Days</span>
                                        </div>

                                        <form onSubmit={(e) => { e.preventDefault(); updateEmployeeLeaveCycleById(item.id) }}>
                                            {active == item.id && <input type='number' value={bonusLeaveDays} onChange={(e) => setBonusLeaveDays(e.target.value)} className='border rounded-1 me-1 bonus-field' placeholder='Enter bonus leave days' />}
                                            <button onClick={() => { setActive(item.id); setBonusLeaveDays(item.bonusLeaveDays) }} type="button" class="btn btn-sm btn-primary py-0  py-1"><i class="fas fa-pen fa-md"></i></button>
                                            {active == item.id && <button type="submit" class="btn btn-sm btn-primary py-0  py-1 ms-1"><i class="fas fa-save fa-md "></i></button>}
                                        </form>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </>))}
                </div>
            </section>
        </>
    )
}
export default EmployeeReward;