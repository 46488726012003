import DOMPurify from "dompurify";
import { saveAs } from "file-saver";
export const baseUrl = "https://localhost:7029/api/";

/*************{ value comparisons } *********/

export const compareDate = (a, operation, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);

    switch (operation) {
        case '==':
            return dateA.getTime() === dateB.getTime();
        case '===':
            return dateA.getTime() === dateB.getTime();
        case '!=':
            return dateA.getTime() !== dateB.getTime();
        case '!==':
            return dateA.getTime() !== dateB.getTime();
        case '>':
            return dateA > dateB;
        case '>=':
            return dateA >= dateB;
        case '<':
            return dateA < dateB;
        case '<=':
            return dateA <= dateB;
        default:
            console.log("ERROR");
            throw new Error('Invalid comparison operation');
    }
};

/*************{ Alert  Message Components } *********/

export const successMessage = (message) => {
    return {
        icon: 'success',
        text: message,
        confirmButtonColor: '#2bc1da',
        confirmButtonText: 'Okay'
    }
}

export const infoMessage = (message) => {
    return {
        icon: 'info',
        text: message,
        confirmButtonColor: '#2bc1da',
        confirmButtonText: 'Okay'
    }
}

export const errorMessage = (message) => {
    return {
        icon: 'error',
        text: message,
        confirmButtonColor: '#2bc1da',
        confirmButtonText: 'Okay'
    }
}

/*************{ File Download } *********/

export const download = (response) => {


    const contentDisposition = response.headers['content-disposition'];
    let filename = 'file';
    if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (filenameMatch) {
            filename = filenameMatch[1];
        }
    }
    const file = new Blob([response.data], { type: response.data.type });
    saveAs(file, filename)
}


export const isDateDifferenceGreaterThanN = (startDate) => {
    // Set the start date
    const endDate = new Date(startDate);

    // Get today's date
    const today = new Date();

    if (compareDate(endDate, "==", today)) {
        return 0;
    } else if (compareDate(today, ">", endDate)) {
        return 0;
    }

    const days = calculateDateDifferenceExcludingWeekends(today, endDate)+1;
    return days;
}

function calculateDateDifferenceExcludingWeekends(startDate, endDate) {
    let numSaturdays = 0;
    let numSundays = 0;

    const daysDiff = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

    for (let i = 0; i <= daysDiff; i++) {
        const currentDay = new Date(startDate.getTime() + i * (1000 * 60 * 60 * 24));
        const dayOfWeek = currentDay.getDay();

        if (dayOfWeek === 6) {
            numSaturdays++;
        } else if (dayOfWeek === 0) {
            numSundays++;
        }
    }
    return daysDiff - (numSaturdays + numSundays) + 1;
}

export const sanitize = (value) => {
    var noSanitize = ["0", 0, 1, "1", false, true];
    if (noSanitize.includes(value)) {
        return value;
    } else {
        return DOMPurify.sanitize(value);
    }
}

export const testRegex = (value, regex) => {
    if (!(regex instanceof RegExp)) { throw new Error('Invalid regular expression provided'); }
    return regex.test(value);
};