import { useSelector } from 'react-redux';


export const usePermissions = () => {
    const { permissions } = useSelector(state => state.permissions);

    const hasPermissions = (array) => {
        var authorized =false;
        for (let i = 0; i < array.length; i++) {
            if(permissions.includes(array[i])){
                authorized = true;
            }
        }
        return authorized
    }

    return [hasPermissions];
}


export const useEmployeeId = () => {
    const { userDetails } = useSelector(state => state);
    if (userDetails && userDetails.userDetails) {
        return userDetails.userDetails.employeeId
    }
    return 0;
}

export const useRoles = () => {
    
    const { userDetails } = useSelector(state => state);
   
    const hasRoles = (array) => {
        var authorized =false;
        if (userDetails && userDetails.userDetails ) {
            var roles = userDetails.userDetails.roles;
            if(roles && roles.length)
            for (var i = 0; i < roles.length; i++) {
             
                if (roles[i]?.id && array.includes(roles[i]?.id)) {
                    return true
                }
            }
        }
        return authorized
    }

    return [hasRoles];
}




