const Footer = () => {

  return (
    <>
      <div className="footer">
        <div class="copyright">
          © Copyright <strong><span className="text-primary fw-bold">Concise</span><span className="text-secondary">flow</span></strong>. All Rights Reserved
        </div>
      </div>
    </>
  )
}
export default Footer;