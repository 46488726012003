
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ContentContainer from '../../components/Main Content/ContentContainer';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import SubHeader from '../../components/Headers/SubHeader/SubHeader';
import Loader from '../../components/Loader';
import Swal from 'sweetalert2';
import { compareDate, errorMessage, isDateDifferenceGreaterThanN, sanitize, successMessage, testRegex } from '../../components/utils';
import { useSelector } from 'react-redux';
const TempLeaveForm = () => {
    const { roles } = useSelector(state => state.userDetails.userDetails);
    const navigate = useNavigate();
    const [employeeId, setEmployeeId] = useState(useParams().employeeId);
    const [loading, setLoading] = useState(false);
    const [updatingCalender, setUpdatingCalender] = useState(true);
    const [id, setId] = useState(useParams().id)
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [userIsAdmin,setUserIsAdmin]=useState(false)

    const [file, setFile] = useState(null);
    const [showHalfDay, setShowHalfDay] = useState(false)
    const [message1, setMessage1] = useState("");   //showing when startdate is in past dates
    const [message2, setMessage2] = useState("");     //showing when end date is smaller than startdate
    const [calender, setCalender] = useState({
        data: [],
        year: "",
        month: "",
        previousYear: null,
        previousMonth: null,
        nextYear: null,
        nextMonth: null,
        monthName: "",
        currentDay: -1,
    });

    const [balance, setBalance] = useState({
        currentBalance: null,
        daysRequested: null,
        newBalance: null
    });

    const [leave, setLeave] = useState({
        leaveTypeId: null,
        startDate: null,
        endDate: null,
        halfDay: false,
        attachment: null,
        employeeComment: null,
        managerComment: null,
        isMorning: false,
        employeeId: employeeId ? employeeId : 0
    });

    const onChange = (e) => {
        e.preventDefault();
        const { value, name } = e.target;
        if (["halfDay", "isMorning"].includes(name))
            testRegex(value, /^[a-zA-Z\d@,.\s\/-]*$/) && setLeave((state) => { return state.endDate != state.startDate ? { ...state, [name]: value, halfDay: false } : { ...state, [name]: value == "true" ? true : false }; })
        else
            testRegex(value, /^[a-zA-Z\d@,.\s\/-]*$/) && setLeave((state) => { return state.endDate != state.startDate ? { ...state, [name]: value, halfDay: false } : { ...state, [name]: value }; })

    }
    const validateNoticePeriod = (tempLeave, application) => {
        if (leave.startDate && tempLeave && !(isDateDifferenceGreaterThanN(application.startDate) >= tempLeave.noticePeriodDays)) {
            const tempDate = new Date();
            const noticePeriodDays = tempLeave.noticePeriodDays;
            // Calculate the closest date based on notice period
            const closestDate = new Date(tempDate.getTime() + (noticePeriodDays) * (1000 * 60 * 60 * 24));
            // Check if the closest date is a weekend (Saturday or Sunday)
            const dayOfWeek = closestDate.getDay();
            if (dayOfWeek === 0) { // Sunday
                closestDate.setDate(closestDate.getDate() + 1); // Move to Monday
            } else if (dayOfWeek === 6) { // Saturday
                closestDate.setDate(closestDate.getDate() + 2); // Move to Monday
            }

            // Format the date as "Monday January 15, 2001"
            const formattedDate = closestDate.toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
            // setMessage1("start date should be any date starting from "+formattedDate + ".")
            !id && Swal.fire(errorMessage("The closest possible start date for this leave is:\n " + formattedDate + "."))
        }
    }


    const getLeaveTypes = async () => {
        await axios.get("leavetypes")
            .then(res => { setLeaveTypes(res.data.data) })
    }

    const getLeaveById = async () => {
        await axios.get("Leave/" + id)
            .then(res => { setLeave(res.data.data) })
    }

    const addHandler = () => {
        const tempLeaveType = leaveTypes.find((item, key) => item.id == leave.leaveTypeId);
        if ((isDateDifferenceGreaterThanN(leave.startDate) >= tempLeaveType.noticePeriodDays) || userIsAdmin) {
            const formData = new FormData();
            for (const key in leave) {
                if (leave.hasOwnProperty(key)) {
                    formData.append(key, leave[key]);
                }
            }

            formData.append('attachment', file);
            setLoading(true);

            axios.post(employeeId ? `employee/${employeeId}/Leaves/create` : "Leave/create", formData)
                .then(res => {
                    setLoading(false)
                    navigate("/leaves")
                    Swal.fire(successMessage('Successful!'))
                })
                .catch(() => {
                    setLoading(false)
                    Swal.fire(errorMessage("Unsuccessful!"))
                });
        } else {
            tempLeaveType && validateNoticePeriod(tempLeaveType, leave) 
        }
    }

    const updateHandler = () => {
        const formData = new FormData();
        for (const key in leave) {
            if (leave.hasOwnProperty(key)) {
                formData.append(key, leave[key]);
            }
        }
        axios.post("Leave/" + id, formData)
            .then(res => {
                setLoading(false)
                navigate("/leaves")
                Swal.fire(successMessage('Successful!'))
            }).catch(() => {
                setLoading(false)
                Swal.fire(errorMessage("Unsuccessful!"))
            })
    }

    const checkBalance = async () => {
        !id && await axios.post("Leave/balance", leave)
            .then(res => { setBalance(res.data.data) })
            .catch(() => { })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        id ? updateHandler() : addHandler();
    }

    const getLeaveStatuses = () => {
        axios.get("status/leaves-statuses")
            .then(response => { setStatuses(response.data.data) })
            .catch(() => { })

    }

    useEffect(() => {
        getLeaveTypes();
        if (leave.endDate && leave.leaveTypeId && leave.startDate) {
            if (!message1 && !message2)
                checkBalance();
        }
        if (leave.endDate != leave.startDate) {
            setShowHalfDay(false)

        } else if (leave.endDate == leave.startDate && leave.endDate != null) {
            setShowHalfDay(true)
        }

        if (compareDate(leave.endDate, "<", leave.startDate) && leave.startDate && leave.endDate && !userIsAdmin)
            setMessage2("end date should not be smaller than start date")
        else
            setMessage2(() => { return "" })

        const tempLeaveType = leaveTypes.find((item, key) => item.id == leave.leaveTypeId);
        if (leave.startDate && tempLeaveType && !(isDateDifferenceGreaterThanN(leave.startDate) >= tempLeaveType.noticePeriodDays) && !userIsAdmin) {
            tempLeaveType && validateNoticePeriod(tempLeaveType, leave);
        }
    }, [leave])

    const getCalender = async () => {
        await axios.get(`/Holidays/calender?year=${calender.year}&month=${calender.month}`)
            .then(res => { setCalender(res.data); setUpdatingCalender(false); })
            .catch(() => { })
    }

    useEffect(() => {
        getLeaveTypes();
        id && getLeaveById() && getLeaveStatuses();
        if (employeeId)
            setLeave({ ...leave, employeeId: employeeId })
    }, [id, employeeId])

    useEffect(() => { updatingCalender && getCalender(); }, [updatingCalender])

    useEffect(() => { window.history.state == null && navigate("/leaves"); }, [])

    console.log("Hello",roles)

    useEffect(()=>{
        if(roles && roles.length>0){
          var adminRole = roles.find(item=>item.id==1 ||item.id==2)
          if(adminRole){
            setUserIsAdmin(true);
          }
        }
    },[roles])
    return (
        <>
            <ContentContainer>
                <SubHeader icon={"bx bx-book-content"} label={employeeId ? "Applying For Leave For An Employee" : "Leaves form"} previousPage={"/leaves"} />
                <section class="section animate__animated animate__fadeInLeft  zoom-900">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-sm-12 mb-2 '>
                                            <form onSubmit={onSubmit} encType='multipart/form-part'>
                                                <div className='row'>
                                                    <div className='col-lg-4 col-md-8 col-sm-12 '>
                                                        <>
                                                            <div className='row'>

                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class="row mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Start Date {message1 && !id && <span className="error-message">  ({message1})</span>}</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input disabled={id} required onChange={onChange} value={sanitize(leave.startDate)} name="startDate" type="date" class="form-control" placeholder='Enter start date here...' />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class="row mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">End Date {message2 && <span className="error-message">  ({message2})</span>}</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input disabled={id} required onChange={onChange} value={sanitize(leave.endDate)} name="endDate" type="date" class="form-control" placeholder='Enter end date  here...' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class="row mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Leave Type</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <select disabled={id} required onChange={onChange} value={sanitize(leave.leaveTypeId)} name="leaveTypeId" class="form-control" >
                                                                                <option value="">Please select</option>
                                                                                {leaveTypes.map((item, key) => (
                                                                                    <>
                                                                                        <option value={item.id}>{item.name}</option>
                                                                                    </>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {showHalfDay && (
                                                                    <>
                                                                        <div className='col-lg-12 mb-2'>
                                                                            <div class="row mb-1">
                                                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Would you like this leave to be a half day leave?</label>
                                                                                <div class="col-md-12 col-lg-12">
                                                                                    <select disabled={id} required onChange={onChange} value={sanitize(leave.halfDay)} name="halfDay" class="form-control" >
                                                                                        <option value="">Please select</option>
                                                                                        <option value={true}>Yes</option>
                                                                                        <option value={false}>No</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {leave.halfDay && (
                                                                    <>
                                                                        <div className='col-lg-12 mb-2'>
                                                                            <div class="row mb-1">
                                                                                <label for="isMorning" class="col-md-12 col-lg-12  label d-flex align-items-center">Please select your preferred time of absence:</label>
                                                                                <div class="col-md-12 col-lg-12">
                                                                                    <select disabled={id} required onChange={onChange} value={sanitize(leave.isMorning)} name="isMorning" class="form-control" >
                                                                                        <option value="">Please select</option>
                                                                                        <option value={true}>Morning</option>
                                                                                        <option value={false}>Afternoon</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}


                                                                {(leave.leaveTypeId == 2 || leave.leaveTypeId == 3) && (
                                                                    <>
                                                                        <div className='col-lg-12 mb-2'>
                                                                            <div class="row mb-1">
                                                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Please attach a doctors sick Note</label>
                                                                                <div class="col-md-12 col-lg-12">
                                                                                    <input disabled={id} onChange={(e) => { setFile(e.target.files[0]) }} name="attachment" type="file" className='me-2' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}

                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class="row mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Employee Comment</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <textarea rows={4} disabled={id} onChange={onChange} value={sanitize(leave.employeeComment)} name="employeeComment" class="form-control" placeholder='Enter comment  here...' >

                                                                            </textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {id &&
                                                                    <>
                                                                        <div className='col-lg-12 mb-2'>
                                                                            <div class="row mb-1">
                                                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Leave Status</label>
                                                                                <div class="col-md-12 col-lg-12">
                                                                                    <select required onChange={onChange} value={leave.statusId} name="statusId" class="form-control" >
                                                                                        <option value="">Please select</option>
                                                                                        {statuses.map((item, key) => (
                                                                                            <>
                                                                                                <option value={item.id}>{sanitize(item.name)}</option>
                                                                                            </>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-12 mb-2'>
                                                                            <div class="row mb-1">
                                                                                <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center"> Approver Comment</label>
                                                                                <div class="col-md-12 col-lg-12">
                                                                                    <textarea rows={4} onChange={onChange} value={sanitize(leave.managerComment)} name="managerComment" class="form-control" placeholder='Enter comment  here...' >

                                                                                    </textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }

                                                            </div>
                                                        </>
                                                    </div>
                                                    {<div className='col-lg-4 col-md-8 col-sm-12 mb-2 ' >

                                                        <div className='col-lg-12 mb-2'>
                                                            <div className='row'>
                                                                <div className='col-lg-12 col-md-12 col-sm-12 mb-2 '>
                                                                    <div class="row mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Current Balance</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input disabled value={sanitize(balance.currentBalance)} type='number' class="form-control" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-12 col-md-12 col-sm-12 mb-2 '>

                                                                    <div class="row mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Days to be deducted</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input disabled value={sanitize(balance.daysRequested)} type='number' class="form-control" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-12 col-md-12 col-sm-12 mb-2 '>

                                                                    <div class="row mb-1">
                                                                        <label for="currentPassword" class={`col-md-12 col-lg-12  label d-flex align-items-center${balance.newBalance < 0?" text-danger":" "}`}>{balance.newBalance < 0? "You have insufficient balance to apply for this leave":"New Balance"}</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input disabled value={sanitize(balance.newBalance)} type='number' class="form-control" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    }



                                                </div>
                                                <hr />
                                                <div className='d-flex'>
                                                    <button type="button" onClick={() => { navigate("/leaves") }} className='btn btn-sm btn-danger text-light me-2 '>Cancel</button>
                                                    <button disabled={(balance.newBalance < 0) || message2 || !leave.leaveTypeId || loading} type='submit' className='btn btn-sm btn-primary text-light ms-auto'>
                                                        <Loader loading={loading} />
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ContentContainer>
        </>
    )
}
export default TempLeaveForm;