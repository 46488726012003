import React from 'react';
import ContentContainer from '../../components/Main Content/ContentContainer';
import LeaveTypesConfigurations from './LeaveTypesConfigurations';
import EmailRecipientConfigurations from './EmailRecipientConfigurations';
import SubHeader from '../../components/Headers/SubHeader/SubHeader';

const Configurations = () => {
  return (
    <>
      <ContentContainer>
        <SubHeader icon={"bx bxs-cog"} label="Configurations" previousPage={"/"} />
        <div class="row">
          <div class="col-8">
            <div class="card">
              <div class="card-header border-0 mb-0 pb-0 d-flex justify-content-start">
                <h6 class="header-3">Leave Types Configuarations</h6>
              </div>
              <div class="card-body d-flex">
                <LeaveTypesConfigurations />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-12 col-md-8">
            <div class="card">
              <div class="card-header border-0 mb-0 pb-0 d-flex justify-content-start">
                <h6 class="header-3">Intercept all emails to... </h6>
              </div>
              <div class="card-body d-flex">
                <EmailRecipientConfigurations />
              </div>
            </div>
          </div>
        </div>
      </ContentContainer>
    </>
  )
}
export default Configurations;
