import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { sanitize, testRegex } from '../../components/utils';

const EmailRecipientConfigurations = () => {
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState("");

  const getEmails = async () => {
    await axios.get("recipient")
      .then(response => { setEmails(response.data.data) })
      .catch(() => { });
  }

  const removeEmail = async () => {
    await axios.delete("recipient")
      .then(res => { getEmails(); })
      .catch(() => { });
  }

  const createEmail = async () => {
    await axios.post("recipient/" + email)
      .then(response => { getEmails(); setEmail(""); })
      .catch(() => { });
  }

  useEffect(() => { getEmails(); }, []);

  return (
    <div class="table-responsive w-100">
      <div className='d-flex mb-3'>
        <input type='text' onChange={(e) => { testRegex(e.target.value,/^[a-zA-Z\d@,.\s\/-]*$/) && setEmail(e.target.value) }} class="form-control flex-grow-1" value={email} placeholder='Enter start date here...' />
        <button onClick={() => { createEmail() }} class="btn btn-sm btn-primary my-0 text-nowrap ms-2">Add Email</button>
      </div>
      <table class="table table-striped">
        <tbody>
          {emails.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{sanitize(item.email)}</td>
              <td className='py-0'>
                <button onClick={() => { removeEmail() }} class="btn btn-sm btn-primary my-1 w-100">Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
};

export default EmailRecipientConfigurations;
