import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Link from './Link';
import Header from '../../pages/Header';
import NavigationHeader from './NavigationHeader';
import { logout } from '../../actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';




const Sidebar = () => {
  const dispatch = useDispatch();
  const { navigation } = useSelector(state => state.navigation);
  const fetchData = () => dispatch => {
    dispatch({
      type: 'FETCH_NAVIGATION_REQUEST',
    });
    axios.get("access-control/navigation")
      .then(response => {
        dispatch({
          type: 'FETCH_NAVIGATION_SUCCESS',
          payload: response.data.data
        })
      })
  }

  const getUserPermissions = () => dispatch => {
    dispatch({
      type: 'FETCH_NAVIGATION_REQUEST',
    });
    axios.get("access-control/GetUserPermissions")
      .then(response => {
        dispatch({
          type: 'FETCH_PERMISSIONS_SUCCESS',
          payload: response.data.data
        })
      })
  }


  
  useEffect(() => {
    dispatch(fetchData())
    dispatch(getUserPermissions())
  }, [])



  return (
    <>
      <Header />
      <aside id="sidebar" className="sidebar rounded-1 py-3 shadowd">
        <NavigationHeader />
        <div className='navigation-container'>
          <ul class="sidebar-nav pt-3" id="sidebar-nav">
            {
              navigation.map((item, key) => (
                <Link key={key} path={item.link} icon={item.icon} label={item.name} />
              ))}
                {/* <Link  path={"/holidays"} icon={"bi bi-people"} label={"Holidays"} />
                <Link  path={"/leave-types"} icon={"bi bi-people"} label={"Leave Types"} /> */}


          </ul>
        </div> 
        <ul class="sidebar-nav logout-link" >
        <li class="nav-item my-1 ms-2">
          <a className="nav-link p-1" onClick={() => { dispatch(logout()) }}>
            <span className='nav-icon navigation d-flex me-2'>
              <i class={"m-auto bx bx-log-out text-dark"}></i>
            </span>
            <span>Logout</span>
          </a>
        </li>
        </ul>
      </aside>
    </>
  )
}
export default Sidebar;
