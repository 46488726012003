import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Swal from 'sweetalert2';
import SubHeader from '../../components/Headers/SubHeader/SubHeader';
import ContentContainer from '../../components/Main Content/ContentContainer';
import { errorMessage, sanitize, successMessage, testRegex } from '../../components/utils';

const LeaveTypeForm = () => {
    const [loading, setLoading] = useState(false);
    const [id] = useState(useParams().id)
    const navigate = useNavigate();
    const [data, setData] = useState({});

    const onChange = (e) => {
        var { value, name, type, checked } = e.target;
        type == "checkbox" ? setData(prevData => ({ ...prevData, [name]: checked })) : setData(prevData => ({ ...prevData, [name]: value }))
    }
    // testRegex(value, /^[a-zA-Z\d@,.\s\/-]*$/)
    const getEmailLeaveTypeById = async () => {
        await axios.get("leavetypes/" + id)
            .then(res => { setData(res.data.data) })
            .catch(res => { })
    }

    const updateHandler = async () => {
        await axios.post("leavetypes/" + id, data)
            .then(res => { setLoading(false); navigate("/configurations"); Swal.fire(successMessage("Successful!")); })
            .catch(() => { Swal.fire(errorMessage('Unsuccessful!')); })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        id && updateHandler();
    }

    useEffect(() => { getEmailLeaveTypeById(); }, [id])
    return (
        <>
            <ContentContainer>
                <SubHeader icon={"bx bx-book-content"} label={"Leave Type Configurations"} previousPage={"/configurations"} />
                <section class="section animate__animated animate__fadeInLeft  zoom-900">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-sm-12 mb-2 '>
                                            <form onSubmit={onSubmit}>
                                                <>
                                                    <div className='row'>
                                                        <div className='col-lg-6 mb-2'>
                                                            <div className='row'>
                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class=" mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Name</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input disabled required onChange={onChange} value={sanitize(data.name)} name="name" type="text" class="form-control" placeholder='Enter start date here...' />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class="row mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Description</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <textarea disabled required onChange={onChange} value={sanitize(data.description)} name="description" type="text" class="border w-100 form-control" rows={2} placeholder='Enter description here...' >
                                                                            </textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class=" mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Total leave balance per cycle( In days )</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input disabled required onChange={onChange} value={sanitize(data.totalDays)} name="totalDays" type="number" class="form-control" placeholder='Enter total balance here...' />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class=" mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Cycle Length( In months )</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input disabled required onChange={onChange} value={sanitize(data.cycleLength)} name="cycleLength" type="number" class="form-control" placeholder='Enter cycle length here...' />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class=" mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Notice Period Days( Excluding weekends )</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input required onChange={onChange} value={sanitize(data.noticePeriodDays)} name="noticePeriodDays" type="number" class="form-control" placeholder='Enter notice period days here...' />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class=" mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Minimum Months To Qualify</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input required onChange={onChange} value={sanitize(data.minimumMonthsToQualify)} name="minimumMonthsToQualify" type="number" class="form-control" placeholder='Enter start date here...' />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-lg-12 mb-2'>
                                                                    <div class=" mb-1">
                                                                        <label for="currentPassword" class="col-md-12 col-lg-12  label d-flex align-items-center">Supporting Document</label>
                                                                        <div class="col-md-12 col-lg-12">
                                                                            <input onChange={onChange} checked={data.supportingDocument} name="supportingDocument" type="checkbox" class="" placeholder='Enter start date here...' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 mb-2'>

                                                        </div>
                                                    </div>
                                                </>
                                                <div className='d-flex '>
                                                    <button type="button" onClick={() => navigate("/configurations")} className='btn btn-sm btn-warning text-light me-2'>Cancel</button>
                                                    <button disabled={loading} type='submit' className='btn btn-sm btn-primary text-light ms-auto'>
                                                        <Loader loading={loading} />
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ContentContainer>
        </>
    )
}
export default LeaveTypeForm;


