import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ContentContainer from '../../components/Main Content/ContentContainer';
import {  useNavigate } from "react-router-dom";

const LeaveTypes = () => {
  const [leaveTypes, setLeaveTypes] = useState([]);
  const navigate = useNavigate();

  const getLeaveTypes = async () => {
    await axios.get("leavetypes")
      .then(res => { setLeaveTypes(res.data.data) })
      .catch(() => { })
  }

  useEffect(() => { getLeaveTypes(); }, [])
  return (
    <>
      <ContentContainer>
        <div class="row mt-4">
          <div className='col-12'>
            <div className='card'>
              <div className='card-body d-flex'>
                <div class="header-1 text-uppercase">
                  Leave Types
                </div>
                <button type="button" onClick={() => navigate("/users")} className='btn btn-sm bg-warning text-light me-2 ms-auto'>Cancel</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          {leaveTypes.map((item, key) => (
            <>
              <div class="col-lg-4 col-md-6 mb-4" key={item.id}>
                <div class="card">
                  <div class="card-body p-3">
                    <div class="d-flex">
                      <div class="avatar avatar-lg bg-dark border-radius-md p-2">
                        <img src="../../assets/img/small-logos/logo-jira.svg" alt="asana_logo" />
                      </div>
                      <div class="ms-3 my-auto">
                        <h6 className='header-3'>{item.name}</h6>
                      </div>
                    </div>
                    <p class="text-sm mt-3">{item.description ? item.description : "No description for this leave type"}</p>
                    <hr class="horizontal dark" />
                    <div class="row">
                      <div class="col-6">
                        <p class="text-sm mb-0 text-secondary">{item.cycleLength} months</p>
                        <p class=" text-sm font-weight-bold mb-0">Cycle Length</p>
                      </div>
                      <div class="col-6 text-end">
                        <p class="text-sm mb-0 text-secondary">{item.totalDays}</p>
                        <p class=" text-sm font-weight-bold mb-0">Total Leave Days</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </ContentContainer>
    </>
  )
}
export default LeaveTypes;
