import { combineReducers } from "redux";
import { loginReducer} from "./authReducer";
import { navigationReducer } from "./navigationReducer";
import { userReducer } from "./UserReducer";
import { permissionsReducer } from "./permissionsReducer";

export default combineReducers({
    userInfo:loginReducer,
    navigation:navigationReducer,
    userDetails:userReducer,
    permissions:permissionsReducer
});