import { useEffect, useState } from "react";
import SquareWidget from "../../../components/Widgets/SquareWidget";
import axios from "axios";

const LeaveBalances = ({ className }) => {
    const [leaveBalances, setLeaveBalances] = useState([]);

    const getLeaveBalances = async () => {
        await axios.get("Leave/getLeaveBalances")
            .then(res => { setLeaveBalances(res.data.data) })
            .catch(() => { }); 
    }

    useEffect(() => { getLeaveBalances(); }, [])
    return (
        <>
            <div class={`row ${className}`}>
                <div class="col-lg-12">
                    <div class="p-0 ">
                        <div class="row ">
                            {leaveBalances.map((item, key) => (
                                <SquareWidget icon={`fa-solid fa-${item.name == "Sick Leave" ? "stethoscope" : item.name == "Annual Leave" ? "calendar-days" : item.name == "Family Responsibility Leave" ? "people-roof" : "user"}`} key={key}
                                    value={item.count}
                                    label={item.name == "Family Responsibility Leave" ? "Family Res. Leave" : item.name}
                                    className={`col-lg-3 col-md-6 col-sm-6 mb-4 mb-lg-2 mb-md-4 mb-sm-4 `}
                                    bgColor={item.name == "Sick Leave" ? "bg-secondary" : item.name == "Annual Leave" ? "bg-primary" : item.name == "Family Responsibility Leave" ? "bg-warning" : "user"} />
                            ))}

                            {leaveBalances.length != 0 && (
                                <SquareWidget bgColor={"bg-primary"} icon="fa-solid fa-calculator" value={leaveBalances.reduce((accumulator, item) => {
                                    return accumulator + item.count;
                                }, 0)} label={"Total Days"} className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-lg-2 mb-md-4 mb-sm-4" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeaveBalances;