import axios from "axios";
import { useEffect, useState } from "react";

const FiltersItem = (props) => {
    const { column, table, label, referenceTable } = props;
    const { target, setTarget } = props;
    const { clear, setClear } = props;
    const { onApply } = props;
    const [collapse, setCollapse] = useState(true);
    const [data, setData] = useState([]);
    const [ids, setIds] = useState([]);
    const [selected, setSelected] = useState([]);

    const getFilters = () => {
        axios.post('filters', { referenceTable: referenceTable, column: column, table: table }).then(res => setData(res.data.data));
    }
    const setFilters = (temp) => {
        axios.post('filters/create', { referenceTable: referenceTable, column: column, ids: temp }).then(res => {
            onApply();
        });
    }
    const submit = (e) => {
        var temp = [];
        for (var i = 0; i < e.target.length; i++) {
            if (e.target[i].name === "column_name" && e.target[i].checked) {
                temp[temp.length] = Number(e.target[i].value);
            }
        }
       
        setIds(temp);
        console.log(temp)
        if (data.length > 0 && temp.length > 0) { 
            setSelected(temp); 
            setFilters(temp)
        }
        
    }

    useEffect(() => {
        if (clear) {
            axios.get('filters/clear', {}).then(res => { onApply(); });
            setSelected([]);
            setData([]);
            setTarget("none")
            setClear(false);
           
        }
    }, [clear])

    useEffect(() => {
        if (target === label) { setCollapse(false); }
        else { setCollapse(true); }
    }, [target])

    useEffect(() => {
        if (target === label) { getFilters(); }
    }, [selected])
    return (
        <>
            <div className="dropdown ">



                <button onClick={() => {
                    setCollapse(false);
                    getFilters();
                    setTarget(label);
                }}
                    id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded={!collapse}
                    type="button" class="btn btn-sm  me-1 text-light border">
                    {label ? label : "No label"} : <span class="badge  btn-secondary rounded-1 ">{selected.length === 0 ? "All" : selected.length} </span>
                </button>

                <ul className={`dropdown-menu cborder  shadow-1 ${collapse ? '' : 'show'}`} aria-labelledby="dropdownMenuButton2">
                    <div className="card-body px-0 pt-0 pb-0">
                        <div className="table-responsiv p-0">
                            <form id="filter-form" onSubmit={(e) => { e.preventDefault(); submit(e); }}>
                                <table className="table align-items-center justify-content-center mb-0">
                                    <thead>
                                        <tr>
                                            <th className="ps-3 font-weight-bolder">
                                                Filters
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="d-flex px-2">
                                                        <input type="checkbox" value={item.id} name="column_name"
                                                            defaultChecked={selected.includes(item.id)} />
                                                        <div className="my-auto">
                                                            <p className="mb-0 text-sm ms-2">{key + 1}</p>
                                                        </div>
                                                        <div className="my-auto">
                                                            <p className="mb-0 text-sm ms-2 text-nowrap">{item.name}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="d-flex mx-1 mt-3 mt-0 mb-0">
                                    <button type="button" className="btn btn-sm rounded-1 mb-0  border me-3" onClick={() => { setCollapse(true) }}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-sm rounded-1 btn-secondary  ms-auto mb-0 text-light " >
                                        Apply
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </ul>
            </div>
        </>
    )
}
export default FiltersItem