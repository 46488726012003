import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import ContentContainer from '../../components/Main Content/ContentContainer';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { usePermissions } from '../../components/Authorization/Authorization';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Calender from '../../components/Calender/Calender';
import RecentLeaves from '../../components/RecentLeaves/RecentLeaves';
import LeaveBalances from './Components/LeaveBalances';
import { sanitize } from '../../components/utils';

const Dashboard = () => {
  const { permissions } = useSelector(state => state.permissions);
  const [activityLogs, setActivityLogs] = useState([]);
  const [leaveStatusCounts, setLeaveStatusCounts] = useState([]);
  const [leaveTypeCounts, setLeaveTypeCounts] = useState([]);
  const [leaveCountsByMonth, setLeaveCountsByMonth] = useState([]);
  const [recentLeaves, setRecentLeaves] = useState([]);
  const [updatingCalender, setUpdatingCalender] = useState(true);
  const [hasPermissions] = usePermissions();

  const [calender, setCalender] = useState({
    data: [],
    year: "",
    month: "",
    previousYear: null,
    previousMonth: null,
    nextYear: null,
    nextMonth: null,
    monthName: "",
    currentDay: -1,
  });

  const chartProps1 = {
    type: 'column',
    title: '',
    categories: leaveCountsByMonth.map(item => sanitize(item.month)),
    colorByPoint: false,
    colors: ['#2bc1da', '#2bc1da'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: false,
    series: [
      {
        type: 'column',
        name: '',
        data: leaveCountsByMonth.map(item => Number(sanitize(item.count))),
        dataLabels: {
          enabled: true,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#2bc1da',
        marker: {
          lineWidth: 2,
          lineColor: '#2bc1da',
          fillColor: '#2bc1da'
        }
      }
    ]
  };

  const options2 = {
    chart: { type: "donut" },
    // dataLabels: { enabled: true },
    tooltip: { enabled: true },

    states: {
      hover: { filter: { type: "lighten", value: 0.5 } },
      active: { filter: { type: "none", value: 0 } }
    },
    stroke: { width: 0 },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "50%",

          labels: {
            style: {
              backgroundPositionY: "34px",
            },
            show: true,
            name: { show: false },
            // total: {
            //   show: true,
            //   showAlways: true,
            //   formatter: function (w) {
            //     const totals = w.globals.seriesTotals;

            //     const result = totals.reduce((a, b) => a + b, 0);

            //     return (result)
            //   }
            // }
          }
        }
      }
    }
    ,
    sparkline: {
      enabled: true
    },
    legend: {
      show: true
    },
    labels: leaveTypeCounts.map(item => sanitize(item.leaveType)),
    colors: ["rgb(181, 30, 143)", "#4ad4ec", "rgb(156, 155, 150)"]
  }
  const data = leaveTypeCounts.map(item => Number(sanitize(item.count)))

  const fetchData = async () => {
    await axios.get("activitylogs")
      .then(res => {
        setActivityLogs(res.data.data)
      })
  }

  const countLeavesByStatus = async () => {
    await axios.get("Leave/CountLeavesByStatus")
      .then(res => {
        setLeaveStatusCounts(res.data.data)

      })
  }
  const countLeavesByLeaveType = async () => {
    await axios.get("Leave/CountLeavesByLeaveType")
      .then(res => {
        setLeaveTypeCounts(res.data.data)
      })
  }

  const countLeavesByMonth = async () => {
    await axios.get("Leave/CountLeavesByMonth")
      .then(res => {
        setLeaveCountsByMonth(res.data.data)
      })
  }

  const getRecentLeaves = async () => {
    await axios.get("Leave/GetRecentLeaves")
      .then(res => {
        setRecentLeaves(res.data.data)
      })
  }
  const getCalender = async () => {
    await axios.get(`/Holidays/calender?year=${calender.year}&month=${calender.month}`)
      .then(res => {
        setCalender(res.data)
        setUpdatingCalender(false)
      })
  }
  const nextMonth = () => {
    setCalender({ ...calender, month: calender.nextMonth, year: calender.nextYear })
    setUpdatingCalender(true);
  }

  const previousMonth = () => {
    setCalender({ ...calender, month: calender.previousMonth, year: calender.previousYear })
    setUpdatingCalender(true);
  }

  const getOptions = (props) => ({

    chart: {
      type: props.type,
      backgroundColor: 'white',
      height: "230",
      // width: "1000"
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled,

    },
    yAxis: {
      title: {
        text: 'Total Leaves',
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'scroll',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });


  useEffect(() => { updatingCalender && getCalender() }, [updatingCalender])

  useEffect(() => {
    if (hasPermissions([2])) {
      fetchData();
      countLeavesByStatus();
      countLeavesByLeaveType();
      countLeavesByMonth();
      getRecentLeaves();
    }
  }, [permissions])

  return (
    <>
      {hasPermissions([2]) && (
        <>
          <ContentContainer>
            <section class="section dashboard ">
              <LeaveBalances className="mt-4" />
              <div class="row mt-4 mt-lg-3  mt-md-0 mt-sm-0">
                <div class="col-lg-7 mb-4 mb-lg-0   mb-md-4 mb-sm-4">
                  <div class="card h-100 p-0 rounded-2 shadow">
                    <div class="overflow-hidden position-relative border-radius-lg bg-cover h-100 rounded-2" >
                      <span class="mask bg-primary"></span>
                      <div class="card-body position-relative z-index-1 d-flex flex-column h-100 p-3">
                        <h5 class="text-white font-weight-bolder mb-4 pt-2">Human Resource System</h5>
                        <p class="text-white">Welcome to our comprehensive HR system, designed to streamline your human resources processes and revolutionize your workplace management.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="card p-0 rounded-2 mb-0">
                    <h6 className='text-start mx-3 py-2 bg-light rounded-1 mt-1 ps-2 header-3'>Leaves overview</h6>
                    <div class="col-lg-12 col-12">
                      <div class="card-body pb-3 p-3 pt-0">
                        <ul class="list-group">

                          {leaveStatusCounts.map((item, key) => (
                            <>
                              <li key={key} class="py-1 list-group-item border-0 d-flex align-items-center px-0 mb-0">
                                <div class="w-100">
                                  <div class="d-flex mb-0">
                                    <p class="me-2 text-sm font-weight-bold text-capitalize mb-2">{sanitize(item.status)} ({sanitize(item.count)})</p>
                                    <p class="ms-auto text-sm font-weight-bold mb-1">{sanitize(item.percentage)}%</p>
                                  </div>
                                  <div>
                                    <div class="progress progress-md">
                                      <div class={`progress-bar ${item.status === "Pending" ? "bg-primary" : item.status === "Approved" ? "bg-secondary" : item.status === "Rejected" ? "bg-warning" : item.status === "Cancelled" ? "bg-danger" : ""}  ${"width-percentage-" + item.percentage}`} role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </>))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-lg-8 col-md-12 col-sm-12'>
                  <p className='line-label text-grey text-dark'>&nbsp; Number Of Applications Per Leave Type &nbsp;</p>
                  <div className='row'>
                    {leaveTypeCounts.map((item, key) => (
                      <div className='col-lg-3 col-md-3 col-sm-6' key={key}>
                        <div class="card p-1 border-primary">
                          <div class="card-header p-0 d-flex">
                            <div class={`leave-type-icon-container rounded-1 d-flex text-light ${item.leaveType == "Sick Leave" ? "bg-secondary" : item.leaveType == "Annual Leave" ? "bg-primary" : item.leaveType == "Family Responsibility Leave" ? "bg-warningd px-2" : "user"} bg-secondary`}>
                              <i class={` p-0 fa-solid fa-${item.leaveType == "Sick Leave" ? "stethoscope" : item.leaveType == "Annual Leave" ? "calendar-days" : item.leaveType == "Family Responsibility Leave" ? "people-roof" : "user"} m-auto`}></i>
                            </div>
                            <div class="card-body pt-0 p-0 px-1 d-flex flex-column me-0 pe-0">
                              <p class=" mb-0 me-0 text-sm font-weight-bold text-capitalize mb-0 pb-0 text-dark text-end">{item.leaveType == "Family Responsibility Leave" ? "Family Res. Leave" : item.leaveType}</p>
                            </div>
                          </div>
                          <p class="text-gradient m-0 text-end  "><span className="header-1 text-primary" id="status1" >{sanitize(item.count)}</span> <span class=" ms-n2">Applications</span></p>
                        </div>
                      </div>
                    ))}
                    <div className='col-lg-3 col-md-3 col-sm-6' >
                      <div class="card p-1">
                        <div class="card-header p-0 d-flex">
                          <div class="leave-type-icon-container rounded-1 d-flex text-light bg-secondary ">
                            <i class={` p-0 fa-solid fa-solid fa-calculator m-auto`}></i>
                          </div>
                          <div class="card-body pt-0 p-0 px-1 d-flex flex-column">
                            <p class=" mb-0 me-2 text-sm font-weight-bold text-capitalize mb-0 pb-0 text-dark text-end">Total leaves</p>
                          </div>
                        </div>
                        <p class="text-gradient m-0 text-end  "><span className="header-1 text-primary" id="status1" >{leaveTypeCounts.reduce((accumulator, item) => {
                          return accumulator + Number(sanitize(item.count));
                        }, 0)}</span> <span class=" ms-n2">Applications</span></p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12  animate__animated animate__zoomIn" >
                  <div class="card p-0 recent-sales   card-border-tertiary " >
                    <div class="card-body p-0" >
                      <Chart
                        options={options2}
                        series={data}
                        type="donut"
                        width="100%"
                        height="90"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 animate__animated animate__zoomIn" >
                <div class="row ">
                  <div class="col-lg-5 col-md-12 ">
                    <RecentLeaves data={recentLeaves} height='220px' />
                  </div>
                  <div class="col-lg-7 col-md-12">
                    <div class="card  pb-0 px-2">
                      <h6 class="text-start mx-3 py-2 bg-light rounded-1 mt-1 ps-2 header-3">Total leave applications a month</h6>
                      <div class="card-body overflowx-auto"  >
                        <div className='minWidth-143' style={{height:"190px"}}>
                          <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps1)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 animate__animated animate__zoomIn" >
                <div class="row ">
                  <div class="col-lg-8 col-md-12 ">
                    <Calender
                      calender={calender}
                      nextMonth={nextMonth}
                      previousMonth={previousMonth}
                    />

                  </div>
                  <div class="col-lg-4 col-md-12">
                    <div class="card">
                      <h6 className='text-start mx-3 py-2 bg-light rounded-1 mt-1 ps-2 header-3'>Activity Logs</h6>
                      <div class="card-body p-3">
                        <div class="timeline timeline-one-side height-270 overflowy-auto" data-timeline-axis-style="dotted">
                          {activityLogs.map((item, key) => (
                            <>
                              <div class="timeline-block my-0">
                                <span class="timeline-step bg-primary">
                                </span>
                                <div class="timeline-content">
                                  <div className='d-flex'>
                                    <p class="text-dark text-sm  mb-0 pt-0  ">{sanitize(item.description)}</p>
                                    <h6 className='pt-0 ms-auto me-2'><span class="badge bg-secondary rounded-1">{sanitize(item.name)}</span></h6>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </section>
          </ContentContainer>

        </>
      )}

    </>
  )
}
export default Dashboard;




















