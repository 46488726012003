import axios from 'axios';
import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NotificationList from '../components/Notifications/NotificationList';
import { Markup } from 'interweave';
import { decode } from 'html-entities';

const Header = () => {
  const containerRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const { name } = useSelector(state => state.userInfo.userInfo);
  const {jobTitle}  = useSelector(state => state.userDetails.userDetails);
  const {roles}  = useSelector(state => state.userDetails.userDetails);
  const [notifications, setNotifications] = useState([]);
  const count = notifications.filter((item) => !item.viewed).length;
  const [notification, setNotification] = useState(null);
  const [notificationComponent, setNotificationComponent] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [showList, setShowList] = useState(false);
  const toggle = () => {
    // var btn = document.getElementById("header-btn");
    var sidebar = document.getElementById("sidebar");
    var main = document.getElementsByClassName("main");
    if (document.body.contains(sidebar) && main && sidebar.classList.contains("collapse-nav")) {
      sidebar.classList.remove("collapse-nav");

      // main.classList.remove("collapse-nav");
      for (var i = 0; i < main.length; i++) {
        main[i].classList.remove("collapse-nav")
      }
    } else {
      sidebar.classList.add("collapse-nav");
      for (var i = 0; i < main.length; i++) {
        main[i].classList.add("collapse-nav")
      }
    }
  };
  const downloadImage = async () => {
    try {
      const response = await axios.get(`User/image`, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'image/jpeg' });
      const url = URL.createObjectURL(blob);

      setImageSrc(url);
    } catch (error) {
      console.log(error);
    }
  }

  const getUserPermissions = () => dispatch => {
    dispatch({
      type: 'FETCH_NAVIGATION_REQUEST',
    });
    axios.get("access-control/GetUserPermissions")
      .then(response => {
        dispatch({
          type: 'FETCH_PERMISSIONS_SUCCESS',
          payload: response.data.data
        })
      })
  }
  const fetchData = async () => {
    await axios.get("notifications")
      .then(res => { setNotifications(res.data.data) })
      .catch(() => { })
  }

  const markNotificationAsViewed = async (id) => {
    await axios.put("notifications/MarkNotificationAsViewed/" + id)
      .then(res => { fetchData(); })
      .catch(() => { })
  }

  const MarkAllNotificationsAsViewed = async () => {
    await axios.put("notifications/MarkAllNotificationsAsViewed")
      .then(res => { fetchData(); })
      .catch(() => { })
  }

  const MarkNotificationAsNotViewed = async (id) => {
    await axios.put("notifications/MarkNotificationAsNotViewed/" + id)
      .then(res => { fetchData(); setNotification({ ...notification, viewed: false }); })
      .catch(() => { })
  }

  const setNotificationListVisible = () => { setShowList(true) }


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target))
        setNotificationComponent(false)
    };
    document.addEventListener('click', handleClickOutside);
    return () => { document.removeEventListener('click', handleClickOutside); };
  }, [setNotificationComponent]);

  useEffect(() => { downloadImage(); fetchData(); }, [])
  useEffect(() => { dispatch(getUserPermissions()) }, [location.pathname])
  return (
    <>
      <button id='updateHeaderImage' className='d-none' onClick={() => { downloadImage() }}></button>
      <main ref={containerRef} id="main" class="main ">

        {location.pathname != "/" ? (
          <>

            <header id="header" class={`header card  section d-flex`}>

              <span class="mask bg-primary opacity-6x"></span>
              <div class="d-flex flex-row">
                <div class="" style={{ zIndex: 200 }}>
                  <i class="bi bi-list toggle-sidebar-btn text-light" onClick={() => { toggle(); }}></i>
                </div>
                <span className='ms-auto text-dark' style={{ zIndex: "100000" }}>
                  <div class="d-flex align-items-center profile">
                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                      <img src={imageSrc ? imageSrc : "assets/img/profile-img.jpg"} alt="Profile" height={30} width={30} class=" border-secondarysd rounded-2 bg-light mx-0 ms-2" />
                      <div className='d-flex flex-column mx-3'>
                        <span class="d-none d-md-block  ps-2 text-light header-1">{name}</span>
                        <span class="d-none d-md-block  ps-2 text-light ">{jobTitle?jobTitle:roles?.length &&roles.map((item,key)=>item.name).join(", ")}</span>
                      </div>
                      <div className="notification-bell-container rounded-2 d-flex bg-primary-light text-primary position-relative" >
                        <i class="fa-regular fa-bell fa-xl m-auto" onClick={() => { setNotificationListVisible() }}></i>
                        <span className='notification-count position-absolute' onClick={() => { setNotificationListVisible() }}>{count}</span>
                        {
                          showList && <NotificationList
                            MarkAllNotificationsAsViewed={MarkAllNotificationsAsViewed}
                            notifications={notifications}
                            notification={notification}
                            setNotification={setNotification}
                            setShowList={setShowList} 
                            markNotificationAsViewed={markNotificationAsViewed}
                            />
                        }
                      </div>
                    </a>
                  </div>
                </span>
              </div>

              <div class=" d-flex mx-3 mt-auto">
                <nav className="d-flex pt-3 text-light">
                  <ol class="breadcrumb m-0" style={{ zIndex: 200 }}>
                    <li class="breadcrumb-item text-light me-2"><a href="index.html"><i class="bi bi-house-door text-light"></i></a></li>
                    <li class=" text-light"><a className="text-light text-capitalize" href="#">{location.pathname}</a></li>
                  </ol>

                </nav>
              </div>
            </header>
          </>
        ) : (<>
          <header id="header" class={`home-header section d-flex mt-1 rounded-1 border-0`}>
            <div class="d-flex flex-row">
              <div class="" style={{ zIndex: 200 }}>
                <i class="bi bi-list toggle-sidebar-btn ps-0" onClick={() => { toggle(); }}></i>
              </div>
            </div>


            <div class=" d-flex mx-3  flex-grow-1 my-auto">
              <span className=' text-dark'>
                <div class="d-flex align-items-center profile">
                  <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                    <div className='d-flex'>
                      <span class="d-none d-md-block  ps-2 text-dark header-2 mt-2">Dashboard</span>
                    </div>
                  </a>
                </div>
              </span>
              <span className='ms-auto text-dark'>
                <div class="d-flex align-items-center profile">
                  <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                    <img src={imageSrc ? imageSrc : "assets/img/profile-img.jpg"} alt="Profile" height={30} width={30} class=" border-secondarysd rounded-2 bg-light mx-0 ms-2" />
                    <div className='d-flex flex-column mx-3'>
                      <span class="d-none d-md-block  ps-2 text-dark header-1">{name}</span>
                      <span class="d-none d-md-block  ps-2">{jobTitle}</span>

                    </div>
                    <div className="notification-bell-container rounded-2 d-flex bg-primary-light text-primary position-relative" >
                      <i class="fa-regular fa-bell fa-xl m-auto" onClick={() => { setNotificationListVisible() }}></i>
                      <span className='notification-count position-absolute' onClick={() => { setNotificationListVisible() }}>{count}</span>
                      {
                          showList && <NotificationList
                            MarkAllNotificationsAsViewed={MarkAllNotificationsAsViewed}
                            notifications={notifications}
                            notification={notification}
                            setNotification={setNotification}
                            setShowList={setShowList} 
                            markNotificationAsViewed={markNotificationAsViewed}
                            />
                        }
                    </div>

                  </a>
                </div>
              </span>
            </div>

          </header>
        </>)}
      </main>

      {notification && <div class="modal fade show d-block ps-0" id="largeModal" tabindex="-1" aria-modal="true" role="dialog" >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content rounded animate__animated animate__backInDown">
            <div class="modal-header  bg-secondary text-light d-flex">
              <div className='d-flex'>
                <i class="fa-solid fa-envelope  mt-0 pt-1 me-2 fa-md"></i>
                <h6 class="modal-title">{notification.name}</h6>
              </div>
              <button onClick={() => { setNotification(null) }} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row mx-2">
                <div class="col-12">
                  <Markup content={notification && decode(notification.body)} />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              {notification && notification.viewed && <button onClick={() => { notification && MarkNotificationAsNotViewed(notification.id) }} type="button" class="btn btn-primary">Mark As Unread</button>}

              <button onClick={() => { setNotification(null) }} type="button" class="btn btn-secondary btn-danger" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>}


    </>

  )
}
export default Header;
