import { Markup } from 'interweave';

const NotificationList = ({ notifications, notification, setNotification, setShowList, MarkAllNotificationsAsViewed = () => { }, markNotificationAsViewed = () => { } }) => {

    const onViewNotification = (item) => {
        setNotification(item);
        markNotificationAsViewed(item.id)
    }
    return (
        <>
            <div className='position-absolute bg-light rounded-2 shadow animate__animated animate__backInRight' style={{ width: "250px", right: "0", top: "32px",zIndex:100 }}>
                <div className='px-2 text-center py-2 d-flex'><div className='header-2'>Notifications</div> <button onClick={() => { setShowList(false) }} className='ms-auto border rounded-1'>x</button></div>

                <div className='px-2'>
                    <hr />
                    {
                        notifications.map((item, key) => (
                            <div onClick={() => { onViewNotification(item) }} className={`d-flex notification-item py-1 ps-1 rounded-1 ${notification && notification.id == item.id ? "bg-primary text-light" : "text-dark"}`}>
                                <p className={` my-0   `} >{key + 1}. {item.name}</p>
                                <i class={`pt-1 ms-auto me-1 fa-regular fa-envelope${item.viewed ? "-open" : ""}`}></i>
                            </div>
                        ))
                    }
                    {notifications.length==0 && <div className='text- text-dark fw-bold'>You have no notifications</div>}
                    <hr />
                </div>
                <div className='px-2 d-flex'>
                    <button onClick={MarkAllNotificationsAsViewed} className='me-auto border rounded-1 mb-2'>Mark All Read</button>
                </div>
            </div>
        </>)
}
export default NotificationList;