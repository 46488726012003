import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ContentContainer from '../../components/Main Content/ContentContainer';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { sanitize } from '../../components/utils';
const Holidays = () => {
  const [holidays, setHolidays] = useState([]);
  const navigate = useNavigate();

  const getHolidays = async () => {
    await axios.get("Holidays")
      .then(res => { setHolidays(res.data.data) })
      .catch(() => { })
  }

  useEffect(() => { getHolidays(); }, [])
  return (
    <>
      <ContentContainer>
        <div class="row mt-4">
          <div className='col-12'>
            <div className='card '>
              <div className='card-body d-flex'>
                <div class="header-1 text-uppercase">
                  Holiday
                </div>
                <button type="button" onClick={() => navigate("/users")} className='btn btn-sm bg-warning text-light me-2 ms-auto'>Cancel</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row ">
          {holidays.map((item, key) => (
            <>
              <div class="col-lg-4 col-md-6 mb-4">
                <div class="card h-100">
                  <div class="card-body p-3 d-flex flex-column">
                    <div class="d-flex ">
                      <div class="avatar avatar-lg bg-dark border-radius-md p-2">
                        <img src="../../assets/img/small-logos/logo-jira.svg" alt="asana_logo" />
                      </div>
                      <div class="ms-3 my-auto">
                        <h6>{item.name}</h6>
                      </div>
                    </div>
                    <p class="text-sm mt-3 flex-grow-1 mb-0 pb-0">{sanitize(item.description)}</p>
                    <div class="row">
                      <div class="col-6"> </div>
                      <div class="col-6 text-end">
                        <h6 class="text-sm mb-0">{sanitize(item.date)}</h6>
                        <p class="text-secondary text-sm font-weight-bold mb-0">Date</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </ContentContainer>
    </>
  )
}
export default Holidays;
