import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ContentContainer from '../../components/Main Content/ContentContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import EmployeeUpdate from './EmployeeUpdate';
import LeaveApplications from './LeaveApplications';
import EmployeeReward from './EmployeeReward';
const EmployeeProfile = () => {
  const navigate = useNavigate();
  const [id, setId] = useState(useParams().id)
  const [user,setUser] = useState({})
  const [tab, setTab] = useState("Update Profile")

  useEffect(() => {
    window.history.state == null && navigate("/employees")
  }, [])


  const fetchData = async () => {
    await axios.get('Employee/' + id)
      .then(res => { setUser(res.data.data) })
  }
  useEffect(() => { fetchData() }, [])
  return (
    <>
      <ToastContainer />
      <ContentContainer>
        <div class="row">
          <div class="col-lg-12 ">
            <div class="card shadow bg-light mx-1 mb-0 py-1" style={{ marginTop: "-46px", zIndex: "1000" }}>
              <div className='card- d-flex p-2 px-2'>
                <div className='flex-grow-1 text-dark profile-label'>
                  <i className='fa-solid fa-user'></i>
                  <span className='fw-bold'> {user.firstname+" "+user.lastname}'s Profile</span>
                </div>
                <div className='d-flex align-items-center'>
                  {["Update Profile", "Leave Applications","Leave Cycles"].map((item, key) => (
                    <>
                      <button onClick={() => setTab(item)} className={`tab-btn  ms-2 ${tab == item ? "btn-primary text-light" : "text-dark"} `}  >{item}</button>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>

        </div>
        {tab == "Leave Applications" && <LeaveApplications id={id} />}
        {tab == "Update Profile" && <EmployeeUpdate id={id} />}
        {tab == "Leave Cycles" && <EmployeeReward id={id} />}


      </ContentContainer>
    </>
  )
}
export default EmployeeProfile;
