import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from "../../components/DataTable/DataTable";
import { saveAs } from 'file-saver';
import ContentContainer from '../../components/Main Content/ContentContainer';
import Filters from '../../components/Filters/Filters';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Select from '../../components/Select';
import { usePermissions, useRoles } from '../../components/Authorization/Authorization';
import { useSelector } from 'react-redux';

const Employees = () => {
  const { permissions } = useSelector(state => state.permissions);
  const [searchQuery, setSearchQuery] = useState("")
  const [show, setShow] = useState(false)
  const [id, setId] = useState(null)
  const navigate = useNavigate();
  const [hasPermissions] = usePermissions();
  const [hasRoles] = useRoles();
  const [state, setState] = useState({
    rowsPerPage: 10,
    data: [],
    currentPage: 1,
    totalPages: 0,
  });
  const tableoptions = [
    // {
    //   column: '#',
    //   appearAs: '#',
    //   width: '30px',
    //   frozen: true,
    //   render: (object, value, key) => (
    //     <div className={'h-100 d-flex'}>
    //       <div className='m-auto d-flex'>
    //         <input type="checkbox" />
    //         {/* <span className='ms-1'>{key + 1}</span> */}
    //       </div>
    //     </div>
    //   ),
    // },
    {
      column: 'Action',
      appearAs: 'View',
      width: '80px',
      frozen: true,
      render: (object, value) => (
        <button onClick={() => viewEmployee(object.id)} className='btn btn-sm btn-primary py-0 flex-grow-1 py-1'>
          <i class="fas fa-pen fa-md"></i>
        </button>
      ),
    },
    {
      column: 'firstname',
      appearAs: 'Firstname',
      width: '150px',
      frozen: true,
      render: null,
    },
    {
      column: 'lastname',
      appearAs: 'Lastname',
      width: '150px',
      frozen: true,
      render: (object, value) => (
        <div className={`h-100 d-flex align-items-center px-2 ${object.age > 30 ? "btn-primaryh" : "bg-dangerfd"}`}>

          {value}

        </div>
      ),
    },
    {
      column: 'email',
      appearAs: 'Email',
      width: '201px',
      frozen: false,
      render: null,
    },
    {
      column: 'contactNumber',
      appearAs: 'Contact Number',
      width: '',
      frozen: false,
      render: null
    },
    {
      column: 'gender',
      appearAs: 'gender',
      width: '70px',
      frozen: false,
      render: null
    },
    {
      column: 'race',
      appearAs: 'Race',
      width: '70px',
      frozen: false,
      render: null
    },
    // {
    //   column: 'idNumber',
    //   appearAs: 'Id Number',
    //   width: '150px',
    //   frozen: false,
    //   render:null
    // },
    {
      column: 'jobTitle',
      appearAs: 'Job Title',
      width: '260px',
      frozen: false,
      render: null
    },
    {
      column: 'manager',
      appearAs: 'Manager',
      width: '150px',
      frozen: false,
      render: null
    },
    {
      column: 'country',
      appearAs: 'Country',
      width: '150px',
      frozen: false,
      render: null
    },
    {
      column: 'dateOfBirth',
      appearAs: 'Date Of Birth',
      width: '150px',
      frozen: false,
      render: null
    },
    {
      column: 'employeeType',
      appearAs: 'Employee Type',
      width: '150px',
      frozen: false,
      render: null
    },
    // {
    //   column: 'salary',
    //   appearAs: 'Salary',
    //   width: '150px',
    //   frozen: false,
    //   render:null
    // },
    {
      column: 'startDate',
      appearAs: 'Start Date',
      width: '150px',
      frozen: false,
      render: null
    },
    {
      column: 'endDate',
      appearAs: 'End Date',
      width: '150px',
      frozen: false,
      render: null
    },
  ];
  const filterItems = [
    // { table: "statuses", column: "StatusId", label: "Status" },
    { table: "EmployeeTypes", column: "EmployeeTypeID", label: "Employee Type" },
    { table: "Genders", column: "GenderId", label: "Gender" },
    { table: "Races", column: "RaceId", label: "Race" },
    { table: "JobTitles", column: "JobTitleId", label: "Job Title" },
    { table: "Countries", column: "CountryId", label: "Country" },
    // { table: "statuses", column: "ManagerId", label: "Manager" },
  ]

  const fetchData = async () => {
    if (hasPermissions([14])) {
      await axios.post("Employee", {
        page: state.currentPage,
        total: state.rowsPerPage,
        searchQuery
      })
        .then(res => {
          setState({ ...state, data: res.data.data, totalPages: res.data.totalPages })
        })
    }
  }

  // useEffect(()=>{
  //   fetchData()
  // },[permissions])

  const viewEmployee = (id) => {
   navigate("/employees/"+id)
  }

  const onModalClose = () => {
    setId(null);
    setShow(false)
    // fetchData()
  }

  const download = (response) => {
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'file';
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
      if (filenameMatch) {
        filename = filenameMatch[1];
      }
    }
    const file = new Blob([response.data], { type: response.data.type });
    saveAs(file, filename)

  }
  const downloadFile = () => {
    axios.get("Employee/download", { responseType: 'blob' })
      .then(response => {
        download(response)
      });
  };
  const downloadExcel = () => {
    axios.get("Employee/downloadExcel", { responseType: 'blob' })
      .then(response => {
        download(response)
      });
  };

  const GenerateEmployeesLeaveBalancesExcel = () => {
    axios.get("Employee/GenerateEmployeesLeaveBalancesExcel", { responseType: 'blob' })
      .then(response => {
        download(response)
      });
  };


  const importExcel = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append('file', e.target.files[0]);
    axios.post("Employee/import", formdata)
      .then(response => {

        // fetchData()

      }).catch(() => {

      })

  };

  useEffect(() => {
    if (searchQuery)
      fetchData()
  }, [searchQuery])

  const createUser = () => {
    // alert("qw");
    // e.preventDefault();  ;setUpdating(false); setShow(true);
    navigate("/users/create")
  }
  const handleSelect = (option) => {
    setSelectedOption(null);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    console.log("has role", hasRoles([2]))
  }, [])
  return (
    <>
      <ToastContainer />
      <ContentContainer>
        <div class="row ">
          <div class="col-lg-12 my-3">
            <Filters table="Employees" filterItems={filterItems} onApply={() => { fetchData() }} />
          </div>
        </div>
        <input class="d-none" type="file" id="import" onChange={importExcel} />
        <section class=" zoom-xs-50 section animate__animated animate__fadeInLeft">
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card card-border-tertiary ">
                <div className='card-header border-0 mb-0 pb-0 d-flex justify-content-end'>
                  <h6 class='header-2'>EMPLOYEES</h6>

                  <div class="ms-auto d-flex">
                    <div class="search-bar  me-2">
                      <form onSubmit={(e) => { e.preventDefault(); fetchData(); }} class="search-form d-flex align-items-center" method="POST" action="#">
                        <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} name="query" placeholder="Search" title="Enter search keyword" />
                        <button type="submit" title="Search"><i class="bi bi-search"></i></button>
                      </form>
                    </div>
                    {hasPermissions([14]) && <Select className="me-0" placeholder="Export File" onSelect={handleSelect} options={[
                      { label: 'Export CSV', value: 'option1', render: () => { downloadExcel() } },
                      { label: 'Employee Balaces', value: 'option3', render: () => { GenerateEmployeesLeaveBalancesExcel() } },
                    ]}
                    />}

                  </div>
                </div>
                <div className='card-body'>
                  <div className='' style={{ overflowX: "auto" }}>
                    <DataTable
                      backendPagination
                      fetchData={fetchData}
                      className={'table table-striped table-bordereds '}
                      state={state}
                      setState={setState}
                      tableOptions={tableoptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContentContainer>
  
    </>
  )
}
export default Employees;
