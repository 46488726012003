import React, { useState, useEffect } from 'react';

export default function Select({ options,placeholder,className }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (!event.target.closest('.custom-select')) {
      setIsDropdownOpen(false);
    }
  };

 

  return (
    <div className={`custom-select  text-light  ${className?className:"me-2"}`} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
      <div className="selected-option btn-primary text-center" style={{width:"100px"}}>{selectedOption ? selectedOption.label : placeholder}</div>
      {isDropdownOpen && (
        <ul className="options-list">
          {options.map((option) => (
            <li key={option.value} className='text-light btn-primary' onClick={() =>option.render() }>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
