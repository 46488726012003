import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import DataTable from "../../components/DataTable/DataTable";
import { saveAs } from 'file-saver';
import ContentContainer from '../../components/Main Content/ContentContainer';
import Filters from '../../components/Filters/Filters';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Select from '../../components/Select';
import { usePermissions } from '../../components/Authorization/Authorization';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { errorMessage, successMessage } from '../../components/utils';
const Users = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const fileInputRef = useRef(null);
  const { permissions } = useSelector(state => state.permissions);
  const [searchQuery, setSearchQuery] = useState("")
  const navigate = useNavigate();
  const [hasPermissions] = usePermissions();
  const [state, setState] = useState({ rowsPerPage: 10, data: [], currentPage: 1, totalPages: 0 });
  const tableoptions = [

    {
      column: 'Action',
      appearAs: 'Update',
      width: '50px',
      frozen: false,
      render: (object, value) => (
        <button disabled={!hasPermissions([11])} onClick={() => { hasPermissions([11]) ? setEdit(object.id) : console.log("No permission to update") }} className='btn btn-sm btn-primary py-0 flex-grow-1 py-1'>
          <i class="fas fa-pen fa-md"></i>
        </button>
      ),
    },
    {
      column: 'firstname',
      appearAs: 'Firstname',
      width: '150px',
      frozen: false,
      render: null,
    },
    {
      column: 'lastname',
      appearAs: 'Lastname',
      width: '150px',
      frozen: false,
    },
    {
      column: 'email',
      appearAs: 'Email',
      width: '201px',
      frozen: false,
      render: null,
    },
    {
      column: 'cellphone',
      appearAs: 'Cellphone',
      width: '',
      frozen: false,
      render: null
    },
    {
      column: 'dateAdded',
      appearAs: 'Date Added',
      width: '150px',
      frozen: false,
      render: (object, value) => {
        return <>
          {
            <span className=''>{value.split("T")[0]}</span>
          }
        </>
      }
    }
  ];
  const filterItems = [
    { table: "Statuses", column: "StatusId", label: "Status" }
  ]

  const fetchData = async () => {
    const requestObject = {
      page: state.currentPage,
      total: state.rowsPerPage,
      searchQuery
    };
    hasPermissions([10]) && await axios
      .post("User", requestObject)
      .then(res => { setState({ ...state, data: res.data.data, totalPages: res.data.totalPages }) })
  }

  const download = (response) => {
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'file';
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
      if (filenameMatch) {
        filename = filenameMatch[1];
      }
    }
    const file = new Blob([response.data], { type: response.data.type });
    saveAs(file, filename)

  }

  const downloadExcel = () => {
    axios.get("User/downloadExcel", { responseType: 'blob' })
      .then(response => { download(response); Swal.fire(successMessage('Download successful!')); })
      .catch(() => { })
  };

  const importExcel = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append('file', e.target.files[0]);
    axios.post("User/import", formdata)
      .then(response => { Swal.fire(successMessage('Import successful!')); fetchData(); })
      .catch((e) => { Swal.fire(errorMessage(e?.response?.data?.message)); })
    fileInputRef.current.value = "";
  };

  const createUser = () => { navigate("/users/create") };
  const handleSelect = (option) => { setSelectedOption(null); };
  const setEdit = (id) => { navigate("/users/update/" + id) };

  useEffect(() => { fetchData(); }, [permissions]);
  useEffect(() => { hasPermissions([10]) && fetchData() }, [searchQuery])
  return (
    <>
      <ContentContainer>
        <div class="row">
          <div class="col-lg-12 my-3">
            <Filters table="Users" filterItems={filterItems} onApply={hasPermissions([10]) ? fetchData : () => { }} />
          </div>
        </div>
        <input ref={fileInputRef} className="d-none" type="file" id="import" onChange={importExcel} />
        <section class="section animate__animated animate__fadeInLeft ">
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card card-border-tertiary ">
                <div className='card-header border-0 mb-0 pb-0 d-flex justify-content-end'>
                  <h6 class='header-2'>USERS</h6>

                  <div class="ms-auto d-flex">
                    <div class="search-bar  me-2">
                      <form class="search-form d-flex align-items-center">
                        <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} name="query" placeholder="Search" title="Enter search keyword" />
                        <button type="submit" title="Search"><i class="bi bi-search"></i></button>
                      </form>
                    </div>
                    { hasPermissions([10]) && <Select placeholder="Export File" onSelect={handleSelect} options={[{ label: 'Export CSV', value: 'option1', render: () => { downloadExcel() } }]} /> }
                  </div>
                  { hasPermissions([9]) && <button onClick={(e) => { createUser() }} className='btn btn-sm btn-primary  '>Add User</button>}
                </div>
                <div className='card-body'>
                  <div className='overflowx-auto'>
                    <DataTable
                      backendPagination
                      fetchData={fetchData}
                      className={'table table-striped table-bordereds '}
                      state={state}
                      setState={setState}
                      tableOptions={tableoptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContentContainer>
    </>
  )
}
export default Users;
