import React from 'react';
import ContentContainer from '../components/Main Content/ContentContainer';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
    const { token } = useSelector(state => state.userInfo.userInfo);
    if (!token) {
        window.location = "/signin"
    }
    const html = <>
        <div class="container">
            <section class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
                <h1>404</h1>
                <br />
                <h2 className='text-md'>Page Not Found!</h2>

                <img src="assets/img/not-found.svg" class="img-fluid py-5" alt="Page Not Found" />
                <div class="credits">
                    Designed by <a href="https://bootstrapmade.com/">Arnold Khosa</a>
                </div>
            </section>
        </div>
    </>;
    return (
        <>
            {/* {!token &&
                <ContentContainer>
                    {html}
                </ContentContainer>
            } */}

        </>
    )
}
export default ErrorPage;


