import React from 'react';
import ContentContainer from '../../components/Main Content/ContentContainer';
import {useParams } from "react-router-dom";
import EmailTemplateForm from './EmailTemplatesForm';
import SubHeader from '../../components/Headers/SubHeader/SubHeader';

const EmailTemplatesForm = () => {

  return (
    <>
      <ContentContainer>
        <SubHeader icon="bx bx-mail-send"  label="Email Template Form" previousPage="/email-templates" />
        <section class=" zoom-xs-50 section animate__animated animate__fadeInLeft">
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card card-border-tertiary ">
                <div className='card-body'>
                  <EmailTemplateForm id={useParams().id} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContentContainer>

    </>
  )
}
export default EmailTemplatesForm;
