
import axios from "axios";
import { useEffect, useState } from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import FiltersItem from "./FiltersItem";
const Filters = (props) => {
    const { filterItems, onApply, table } = props;
    const [target, setTarget] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [clear, setClear] = useState(false);
    const dateRange = false;
    
    const getRange = (start, end) => {
        var start_date = new Date(Date.parse(start));
        var end_date = new Date(Date.parse(end));
        start_date.setDate(start_date.getDate() + 1);
        start_date = start_date.toISOString().slice(0, 10);
        end_date = end_date.toISOString().slice(0, 10);
        setStartDate(start_date);
        setEndDate(end_date);
        alert(startDate)
    }
    useEffect(() => {
        if (clear) {
            setStartDate("");
            setEndDate("");
        }
        onApply()
    }, [clear])
    useEffect(() => {
        axios.get('filters/clear', {}).then(res => { onApply(); });
    }, [false])

    return (
        <>
            <div id="filters" class="px-3 mb-3 bg-primary shadow  rounded-1">
                <div class="m-0 my-2 d-flex">
                    {filterItems.map((item, key) => (
                        <FiltersItem key={key}
                            referenceTable={item.table}
                            column={item.column}
                            label={item.label}
                            target={target}
                            setTarget={setTarget}
                            clear={clear}
                            setClear={setClear}
                            onApply={onApply}
                            table={table}
                        />
                    ))}
                    {dateRange && (
                        <DateRangePicker onApply={(e, picker) => { getRange(picker.startDate._d, picker.endDate._d) }}>
                            <button onClick={() => { setTarget("date-range") }} className="btn btn-sm text-dark border rounded-1 text-light me-2" type="button" style={{ backgroundColor: "white", border: "3px solid black" }}>{startDate && endDate ? `${startDate + " - " + endDate}` : "Date range"}</button>
                        </DateRangePicker>
                    )}
                    <button onClick={() => { setClear(true) }} type="button" class="btn btn-sm btn-secondary text-light ms-auto">
                        Clear
                    </button>
                </div>
            </div>
        </>
    )
}
export default Filters;
