import React, { useEffect, useState } from 'react';
// import Chatbot from '../chatbot/Chatbot';
import { NavLink } from 'react-bootstrap';
import Footer from '../Footer';
import { Chatbot } from 'cf-react-chatbot';
import 'cf-react-chatbot/dist/index.css'

const ContentContainer = ({ children }) => {
    const [show, setShow] = useState(false)

    const [data, setData] = useState( [
            {
              domain: "Audit trail",
              subDomains: [
                    {
                      name: "Audit Trails",
                        questions: [
                            {
                                question: "What is the aim of an Audit trail?",
                                placeholder: "",
                                answer: "The aim of an Audit trail is to record every interaction made by the user within the system."
                            }
                        ]
                    }
                ]
            },
            {
              domain: "Login Tracker",
              subDomains: [
                    {
                      name: "Login Tracker",
                        questions: [
                            {
                                question: "What is the aim of a Login tracker?",
                                placeholder: "",
                                answer: "The aim of a Login tracker is to keep records of users that have logged into a system. It also keeps records of login error attempts, whether successful or not."
                            }
                        ]
                    }
                ]
            },
            {
              domain: "UAC",
              subDomains: [
                    {
                      name: "UAC",
                        questions: [
                            {
                                question: "What is UAC?",
                                placeholder: "",
                                answer: "UAC stands for User Access Control, a functionality whereby an Admin/Leader controls the permissions of a user within the system platform."
                            },
                            {
                                question: "What happens when permissions within the system are limited?",
                                placeholder: "",
                                answer: "If certain permissions are disabled for you to access within the platform, you will only be able to see what has been granted access to."
                            },
                            {
                                question: "Where can one generate permissions for a user within the system?",
                                placeholder: "",
                                answer: "Simply go to the UAC functionality and access permission settings for that specific user."
                            },
                            {
                                question: "Can I change a user's role within the system?",
                                placeholder: "",
                                answer: "Absolutely! Simply go to the UAC functionality, search for the user you want to change the role for, and make the necessary changes."
                            }
                        ]
                    }
                ]
            },
            {
              domain: "Users",
              subDomains: [
                    {
                      name: "Users",
                        questions: [
                            {
                                question: "Where can I update a user's profile information?",
                                placeholder: "",
                                answer: "You can update user profiles by clicking the Users functionality. A list of users within the system will display, and you can update them as required, or you can perform bulk uploads."
                            }
                        ]
                    }
                ]
            },
            {
              domain: "Email template",
              subDomains: [
                    {
                      name: "Email",
                        questions: [
                            {
                                question: "How do I know how the email will display to the users?",
                                placeholder: "",
                                answer: "You can preview your email before saving it by clicking the preview button."
                            },
                            {
                                question: "What is the aim of the Email template?",
                                placeholder: "",
                                answer: "The aim of the Email template is to manage the messages sent out to the users."
                            }
                        ]
                    }
                ]
             },
            {
              domain: "Leave",
              subDomains: [
                    {
                      name: "Leave Application process",
                        questions: [
                            {
                                question: "How do I request time off?",
                                placeholder: "",
                                answer: "You can request time off by using the company's leave management system. Please log in and navigate to the 'Leave Request' section."
                            },
                            {
                                question: "How many days of leave do I have remaining?",
                                placeholder: "",
                                answer: "To check your remaining leave balance, please visit the leave management system or contact the HR department."
                            },
                            {
                                question: "Can I take half-day leave?",
                                placeholder: "",
                                answer: "Yes, you can request a half-day leave through the leave management system. Specify the duration and reason for your request."
                            },
                            {
                                question: "Can I cancel or modify a leave request?",
                                placeholder: "",
                                answer: "You may be able to cancel or modify a leave request before it's approved. Please check the system for your request status and follow the provided instructions."
                            },
                            {
                                question: "How long does it take for a leave request to be approved?",
                                placeholder: "",
                                answer: "Leave requests are typically reviewed and approved within [company-specific timeframe]. You will receive a notification once your request has been processed."
                            },
                            {
                                question: "What should I do if my leave request is denied?",
                                placeholder: "",
                                answer: "If your leave request is denied, please reach out to your manager or the HR department to discuss the reason for denial and explore possible alternatives."
                            },
                            {
                                question: "Can I apply for leave for multiple days?",
                                placeholder: "",
                                answer: "Yes, you can apply for leave for multiple consecutive days. Specify the start and end dates when submitting your request."
                            },
                            {
                                question: "Are there any restricted periods for taking leave?",
                                placeholder: "",
                                answer: "If there are any restricted periods, they will be communicated in advance. Please refer to the leave management system or company policy for information on these periods."
                            },
                            {
                                question: "How far in advance should I submit a leave request?",
                                placeholder: "",
                                answer: "It is recommended to submit leave requests well in advance, typically [company-specific timeframe], to ensure proper planning and approval."
                            },
                        ]
                    },
    
    
                  {
                      name: "Leave Types",
                        questions: [
                            {
                                question: "What types of leave are available?",
                                placeholder: "",
                                answer: "The available leave types include Annual leave, sick leave, Family responsibility leave, and any other leave types defined by the company."
                            },
                      
                        ]
                    },
                    
                   {
                      name: "Leave Bonus",
                        questions: [
                            {
                                question: "What is a leave bonus?",
                                placeholder: "",
                                answer: "A leave bonus is a financial incentive or additional compensation provided to employees in recognition of their unused vacation or leave days. It is a form of reward for employees who choose not to take their allotted time off during a specific period."
                            },
                            {
                                question: "Can I give my self a bonus leave?",
                                placeholder: "",
                                answer: "Unfortunately no, Bonus leave can only be assigned by Admin and managers."
                            },
                            {
                                question: "How do I know that I have been granted a bonus leave?",
                                placeholder: "",
                                answer: "In each an every cycle you will be able to see an additional number/s in your total leave cycle."
                            }
                         ]
                      }
                    ]
                 },
    
           {
              domain: "Account",
              subDomains: [
                    {
                      name: "Personal Details",
                        questions: [
                            {
                                question: "How do I update my personal details in my account?",
                                placeholder: "",
                                answer: "To update your personal details, navigate to the 'Account Settings' section on the platform. There, you'll find options to edit and save changes to your contact information."
                            },
                            {
                                question: "What personal details can be updated in the account?",
                                placeholder: "",
                                answer: "You can update details such as your contact number, email address, and mailing address in the account settings."
                            },
                            {
                                question: "Will I receive a confirmation once I update my personal details?",
                                placeholder: "",
                                answer: "Yes, you will receive a confirmation message once the update is successful."
                            }
                        ]
                    }
                ]
             },
          {
              domain: "Dashboard",
              subDomains: [
                    {
                      name: "Calendar",
                        questions: [
                            {
                                question: "How can I access my dashboard?",
                                placeholder: "",
                                answer: "To access your dashboard, click on the 'Dashboard' tab in the main menu. This will give you an overview of your calendar, leave balances, and activities."
                            },
                            {
                                question: "What information does the calendar in the dashboard display?",
                                placeholder: "",
                                answer: "The calendar displays the holidays and also your taken leave days."
                            },
                            {
                                question: "How do I check my leave balances on the dashboard?",
                                placeholder: "",
                                answer: "Your leave balances are visible on the dashboard under the 'Leave Balances' section. It provides a summary of your remaining vacation, sick leave, and other leave types."
                            },
                            {
                                question: "Can I get an overview of my leave history on the dashboard?",
                                placeholder: "",
                                answer: "Certainly! The 'Leave Overview' section on your dashboard provides a detailed history of your past leave requests, including approved, pending, and rejected leaves."
                            }
                        ]
                    }
                ]
            }
        ]
             
      );

    return (
        <>
            <main id="main" className="main position-relative container-bodys">
                {show && (<span style={{ position: "fixed", right: 43, bottom: 20, zIndex: 10000, width: "300px", height: "500px" }} className='d-flex justify-content-center align-items-center animate__animated animate__backInRight' >
                    <>
                        <Chatbot
                            data={data}
                            getConversation={() => { }}
                            conversation={[]}
                            onChange={(value) => { }}
                            onClose={() => { }}
                            onSubmit={() => { }}
                        />
                    </>
                </span>
                )}
                <span style={{ position: "fixed", zIndex: 10000, width: "50px"}} className='d-flex justify-content-center align-items-center ' >
                    <span onClick={() => { setShow(!show); console.log(show) }} style={{}} className=' bg-primary chatbot-toggle  d-flex justify-content-center align-items-center pointer' >
                        <i class={`fa-solid fa-${show?"xmark":"message"}`} style={{ fontSize:"20px"}}></i>
                    </span>
                </span>
                {children}
                <Footer />

            </main>
        </>
    )
}
export default ContentContainer;
