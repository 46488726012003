import axios from 'axios';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Loader from '../../components/Loader';
import { usePermissions } from '../../components/Authorization/Authorization';
import { errorMessage, successMessage } from '../../components/utils';

const UserPasswordForm = () => {
    const [loading, setLoading] = useState(false)
    const [hasPermissions] = usePermissions();
    const [password, setPassword] = useState({
        newPassword: null,
        currentPassword: null,
        confirmPassword: null
    })
    const updateHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        const request = {
            newPassword: password.confirmPassword,
            currentPassword: password.currentPassword,
            confirmPassword: password.confirmPassword
        }

        if (password.confirmPassword === password.newPassword) {
            await axios.post("User/security", request)
                .then(res => { setLoading(false); Swal.fire(successMessage("Successful!")); })
                .catch((e) => { setLoading(false); Swal.fire(errorMessage("Your current password is wrong!")); })
        } else {
            Swal.fire(errorMessage("Passwords do not match!"));
        }
    }

    const onChange = (e) => {
        var value = e.target.value
        var name = e.target.name;
        setPassword({ ...password, [name]: value })
    }
    return (
        <>
            <form onSubmit={updateHandler}>
                <div className='row'>

                    <div className='col-md-12'>
                        <label class="form-label">Current password</label>
                        <div class="form-group">
                            <input disabled={!hasPermissions([47])} value={password.currentPassword} name="currentPassword" onChange={onChange} class="form-control" type="password" placeholder="Current password" onfocus="focused(this)" onfocusout="defocused(this)" />
                        </div>

                        <label class="form-label">New password</label>
                        <div class="form-group">
                            <input disabled={!hasPermissions([47])} value={password.newPassword} onChange={onChange} name="newPassword" class="form-control" type="password" placeholder="New password" onfocus="focused(this)" onfocusout="defocused(this)" />
                        </div>

                        <label class="form-label">Confirm new password</label>
                        <div class="form-group">
                            <input disabled={!hasPermissions([47])} value={password.confirmPassword} onChange={onChange} name="confirmPassword" class="form-control" type="password" placeholder="Confirm password" onfocus="focused(this)" onfocusout="defocused(this)" />
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <h6 class="mt-1 mb-0">Password requirements</h6>
                        <ul class="text-muted ps-4 mb-0 ">
                            <li> <p class="text-sm mb-0">One special characters</p> </li>
                            <li> <p class="text-sm mb-0">Min 6 characters</p> </li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div class="d-flex m">
                    <button disabled={!hasPermissions([47])} type='submit' class="btn btn-sm bg-primary text-light ms-auto mt-auto">
                        { <Loader loading={loading} />}
                        Save changes
                    </button>
                </div>
            </form>

        </>
    )
}
export default UserPasswordForm;

