import { useNavigate } from "react-router-dom";


const SubHeader = ({ icon, label, previousPage }) => {
    const navigate = useNavigate();
    return (
        <>
            <div class="row ">
                <div class="col-lg-12 my-3">
                    <div id="filters" class="px-3 mb-3 bg-primary shadow  rounded-1">
                        <div class="m-0 my-2 d-flex">
                            <div><i class={`m-auto text-light  ${icon?icon:"bx bx-user-plus"} bg-secondary p-1 rounded-4`}></i></div>
                            <div className='header-2 text-light ms-2'>{label}</div>
                            <button type="button" onClick={() => { navigate(previousPage) }} class="btn btn-sm btn-danger text-light ms-auto">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SubHeader;