import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Loader from '../../components/Loader';
import { usePermissions } from '../../components/Authorization/Authorization';
import { useSelector } from 'react-redux';
import { errorMessage, successMessage, testRegex } from '../../components/utils';

const UserForm = ({ setRoles, showToast }) => {
    const [loading, setLoading] = useState(false)
    const [hasPermissions] = usePermissions();
    const { permissions } = useSelector(state => state.permissions);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [file, setFile] = useState(null);
    const [imageSrc, setImageSrc] = useState('');
    const [user, setUser] = useState({})

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('lastname', user.lastname);
        formData.append('firstname', user.firstname);
        formData.append('email', user.email);
        formData.append('cellphone', user.cellphone);

        if (file) {
            formData.append('image', file);
        } else if (!file && !imageSrc && !user.imageName) {
            formData.append('image', null);
            formData.append('removeImage', true);
        } else if (imageSrc && !file) {
            formData.append('image', null);
            formData.append('imageName', user.imageName);
        }
        updateHandler(formData);
    }

    const downloadImage = async (fileName) => {
        try {
            const response = await axios.get(`User/image`, {
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: 'image/jpeg' });
            const url = URL.createObjectURL(blob);

            setImageSrc(url);
        } catch (error) {
            console.log("err", error);
        }
    }

    const handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setFile(e.target.files[0]);
            setImagePreviewUrl(reader.result);
        };

        reader.readAsDataURL(file);
    }
    const updateHandler = (formData) => {
        axios.put("User/profile", formData)
            .then(res => {
                Swal.fire(successMessage('Successful!'));
                setLoading(false)
                var btn = document.getElementById("updateHeaderImage")
                document.contains(btn) && btn.click();
            }).catch((e) => { setLoading(false); Swal.fire(errorMessage("Error!")); })
    }
    const fetchData = async () => {
        await axios.post("User/profile")
            .then(res => { setUser(res.data.data); setRoles(res.data.data.roles); })
            .catch(() => { })
    }

    const onChange = (e) => {
        var value = e.target.value
        var name = e.target.name;
        testRegex(value, /^[a-zA-Z\d\s]+$/) && setUser({ ...user, [name]: value });
    }
    useEffect(() => { fetchData(); downloadImage(); }, [permissions])
    return (
        <>

            <form onSubmit={onSubmit} encType='multipart/form-part' >
                <div class="row">
                    <div class="col-xl-8">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label">Firstname</label>
                                <div class="form-group">
                                    <input   disabled={!hasPermissions([47])} onChange={onChange} name="firstname" type="text" class="form-control" id="fullName" placeholder="Enter firstname" value={user.firstname} />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label class="form-label">Lastname</label>
                                <div class="form-group">
                                    <input  disabled={!hasPermissions([47])} onChange={onChange} name="lastname" type="text" class="form-control" id="Country" placeholder="Enter firstname" value={user.lastname} />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label class="form-label">Email</label>
                                <div class="form-group">
                                    <input  disabled={!hasPermissions([47])} onChange={onChange} name="email" type="text" class="form-control" id="Address" placeholder="Enter firstname" value={user.email} />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label class="form-label">Cellphone</label>
                                <div class="form-group">
                                    <input  disabled={!hasPermissions([47])} onChange={onChange} name="cellphone" type="text" class="form-control" id="Address" placeholder="Enter firstname" value={user.cellphone} />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-xl-4 d-flex flex-column">
                        <img disabled={!hasPermissions([47])} height={180} width={240} src={imagePreviewUrl ? imagePreviewUrl : imageSrc ? imageSrc : "assets/img/profile-img.jpg"} alt="profile_image" class=" rounded-1 shadow mb-auto ms-auto mt-2" onClick={() => document.getElementById("upload").click()} />
                        <div class="d-flex">
                            <button disabled={!hasPermissions([47])} onClick={() => document.getElementById("upload").click()} type='button' class="btn btn-sm btn-primary">Upload image</button>
                            <div className='flex-grow-1'></div>
                            <button disabled={!hasPermissions([47])} onClick={() => {
                                setUser({ ...user, imageName: null });
                                setImagePreviewUrl(null);
                                setImageSrc(null);
                                setFile(null)
                            }
                            } type='button' class="btn btn-sm btn-secondary">Remove Image</button>
                        </div>
                    </div>
                    <input disabled={!hasPermissions([47])} id="upload" className='d-none'  accept=".jpg, .jpeg, .png, .gif" type="file" onChange={handleImageChange} />
                </div>
                <hr />
                <div class="d-flex mt-2">

                    <button disabled={!hasPermissions([47])} type='submit' onClick={() => onSubmit()} class="btn btn-sm bg-primary text-light ms-auto mt-auto">
                        <Loader loading={loading} />
                        Save changes
                    </button>
                </div>
            </form>
        </>
    )
}
export default UserForm;
