const initialState = {
    userInfo:localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
};
export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS': return { ...state, userInfo: action.payload }
        case 'LOGOUT_SUCCESS': return { ...state, userInfo: action.payload }
        default: return { ...state }
    }
}

