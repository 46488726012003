import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from "../../components/DataTable/DataTable";
import ContentContainer from '../../components/Main Content/ContentContainer';
import Filters from '../../components/Filters/Filters';
import { useNavigate } from "react-router-dom";
import { usePermissions } from '../../components/Authorization/Authorization';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { errorMessage, successMessage } from '../../components/utils';

const EmailTemplates = () => {
  const { permissions } = useSelector(state => state.permissions);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [hasPermissions] = usePermissions();

  const [state, setState] = useState({
    rowsPerPage: 10, data: [], currentPage: 1, totalPages: 0, });

  const filterItems = [
    { table: "Components", column: "ComponentId", label: "Pages" }
  ]
  const [tableoptions, setTableoptions] = useState(
    [
      {
        column: 'Update',
        appearAs: 'Update',
        width: '70px',
        frozen: true,

        render: (object, value) => {
          return (
            <button disabled={!hasPermissions([27])} onClick={() => { hasPermissions([27]) && editHandler(object.id) }} className='btn btn-sm btn-primary py-0 flex-grow-1 py-1'>
              <i class="fas fa-pen fa-md"></i>
            </button>
          )
        }
      },
      {
        column: 'Preview',
        appearAs: 'Preview',
        width: '70px',
        frozen: true,

        render: (object, value) => {
          return (
            <button disabled={!hasPermissions([27])} onClick={() => { hasPermissions([27]) && previewEmail(object.id) }} className='btn btn-sm btn-primary py-0 flex-grow-1 py-1'>
              <i class="fas fa-eye fa-md"></i>
            </button>
          )
        }
      },

      {
        column: 'name',
        appearAs: 'Name',
        width: '250px',
        frozen: true,
        render: null,
      },
      {
        column: 'subject',
        appearAs: 'Subject',
        width: '300px',
        frozen: false,
        render: null,
      },
      {
        column: 'component',
        appearAs: 'Page',
        width: '150px',
        frozen: false,
        render: null
      },
      {
        column: 'placeholders',
        appearAs: 'Placeholders',
        width: '450px',
        frozen: false,
        render: null
      },
    ])

  const fetchData = async () => {
    hasPermissions([26]) && await axios.post("EmailTemplates/GetEmailTemplates", { page: state.currentPage, total: state.rowsPerPage, searchQuery })
      .then(res => { setState({ ...state, data: res.data.data, totalPages: res.data.totalPages }) })
      .catch(() => { })
  }

  const editHandler = (id) => { navigate("/email-templates/" + id); }

  const previewEmail = async (id) => {
    await axios.get("EmailTemplates/" + id + "/preview")
      .then(res => { navigate("/email-management"); Swal.fire(successMessage('Successful!')); })
      .catch(() => { Swal.fire(errorMessage("Unsuccessful!")); });
  }

  useEffect(() => { fetchData() }, [permissions])
  return (
    <>
      <ContentContainer>
        <div class="row ">
          <div class="col-lg-12 my-3">
            <Filters table="EmailTemplates" filterItems={filterItems} onApply={() => { fetchData() }} />
          </div>
        </div>
        <section class=" zoom-xs-50 section animate__animated animate__fadeInLeft">
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card card-border-tertiary ">
                <div className='card-header border-0 mb-0 pb-0 d-flex justify-content-start'>
                  <h6 class='header-3'>EMAIL MANAGEMENT</h6>
                </div>
                <div className='card-body'>
                  <div className='overflowx-auto' >
                    <DataTable
                      backendPagination
                      fetchData={() => fetchData()}
                      className={'table table-striped table-bordereds '}
                      state={state}
                      setState={setState}
                      tableOptions={tableoptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContentContainer>
    </>
  )
}
export default EmailTemplates;
