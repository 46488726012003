import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from "../../components/DataTable/DataTable";
import { saveAs } from 'file-saver';
import ContentContainer from '../../components/Main Content/ContentContainer';
import Filters from '../../components/Filters/Filters';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Select from '../../components/Select';
import { usePermissions, useRoles } from '../../components/Authorization/Authorization';
import { useSelector } from 'react-redux';

const Reports = () => {
  const { permissions } = useSelector(state => state.permissions);
  const [searchQuery, setSearchQuery] = useState("")
  const [show, setShow] = useState(false)
  const [id, setId] = useState(null)
  const navigate = useNavigate();
  const [hasPermissions] = usePermissions();
  const [selectedOption,setSelectedOption]= useState(null);
  const [state, setState] = useState({
    rowsPerPage: 10,
    data: [],
    currentPage: 1,
    totalPages: 0,
  });
  const tableoptions = [
    {
      column: 'firstname',
      appearAs: 'Firstname',
      width: '150px',
      frozen: false,
      render: null,
    },
    {
      column: 'lastname',
      appearAs: 'Lastname',
      width: '150px',
      frozen: false,
      render: (object, value) => (
        <div className={`h-100 d-flex align-items-center px-2 ${object.age > 30 ? "btn-primaryh" : "bg-dangerfd"}`}>

          {value}

        </div>
      ),
    },
    {
      column: 'leaveBalances',
      appearAs: 'Annual Leave',
      width: '201px',
      frozen: false,
      render: (object, value) => (
        <div className={`h-100 d-flex align-items-center px-2 ${object.age > 30 ? "btn-primaryh" : "bg-dangerfd"}`}>
         {value.length == 3 && value.find(item=>item.leaveTypeName=="Annual Leave")?.leaveBalance}
        </div>
      ),
    },
    {
      column: 'leaveBalances',
      appearAs: 'Sick Leave',
      width: '201px',
      frozen: false,
      render: (object, value) => (
        <div className={`h-100 d-flex align-items-center px-2 ${object.age > 30 ? "btn-primaryh" : "bg-dangerfd"}`}>

{value.length == 3 && value.find(item=>item.leaveTypeName=="Sick Leave")?.leaveBalance}

        </div>
      ),
    },
    {
      column: 'leaveBalances',
      appearAs: 'Family Responsibility Leave',
      width: '201px',
      frozen: false,
      render: (object, value) => (
        <div className={`h-100 d-flex align-items-center px-2 ${object.age > 30 ? "btn-primaryh" : "bg-dangerfd"}`}>

          {value.length == 3 && value.find(item=>item.leaveTypeName=="Family Responsibility Leave")?.leaveBalance}

        </div>
      ),
    }
  ];
  const filterItems = [
    // { table: "statuses", column: "StatusId", label: "Status" },
    { table: "EmployeeTypes", column: "EmployeeTypeID", label: "Employee Type" },
    { table: "Genders", column: "GenderId", label: "Gender" },
    { table: "Races", column: "RaceId", label: "Race" },
    { table: "JobTitles", column: "JobTitleId", label: "Job Title" },
    { table: "Countries", column: "CountryId", label: "Country" },
    // { table: "statuses", column: "ManagerId", label: "Manager" },
  ]

  const fetchData = async () => {
    if (hasPermissions([42])) {
      await axios.post("Report/GetEmployeesWithBalances", {
        page: state.currentPage,
        total: state.rowsPerPage,
        searchQuery
      })
        .then(res => {
          setState({ ...state, data: res.data.data, totalPages: res.data.totalPages })
        })
    }
  }

  const download = (response) => {
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'file';
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
      if (filenameMatch) {
        filename = filenameMatch[1];
      }
    }
    const file = new Blob([response.data], { type: response.data.type });
    saveAs(file, filename)

  }


  const GenerateEmployeesLeaveBalancesExcel = () => {
    axios.get("Report/GenerateEmployeesLeaveBalancesExcel", { responseType: 'blob' })
      .then(response => {
        download(response)
      });
  };

  useEffect(() => {searchQuery && fetchData()}, [searchQuery,permissions])

  const handleSelect = (option) => {
    setSelectedOption(null);
  };
  return (
    <>
      <ToastContainer />
      <ContentContainer>
        <div class="row ">
          <div class="col-lg-12 my-3">
            <Filters table="Employees" filterItems={filterItems} onApply={() => { fetchData() }} />
          </div>
        </div>
        <section class=" zoom-xs-50 section animate__animated animate__fadeInLeft">
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card card-border-tertiary ">
                <div className='card-header border-0 mb-0 pb-0 d-flex justify-content-end'>
                  <h6 class='header-2'>BALANCES REPORTS</h6>
                  <div class="ms-auto d-flex">
                    <div class="search-bar  me-2">
                      <form onSubmit={(e) => { e.preventDefault(); fetchData(); }} class="search-form d-flex align-items-center" method="POST" action="#">
                        <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} name="query" placeholder="Search" title="Enter search keyword" />
                        <button type="submit" title="Search"><i class="bi bi-search"></i></button>
                      </form>
                    </div>
                    {hasPermissions([42]) && <Select className="me-0" placeholder="Export File" onSelect={handleSelect} options={[
                      { label: 'Employee Balaces', value: 'option3', render: () => { GenerateEmployeesLeaveBalancesExcel() } },
                    ]}
                    />}

                  </div>
                </div>
                <div className='card-body'>
                  <div className='' style={{ overflowX: "auto" }}>
                    <DataTable
                      backendPagination
                      fetchData={fetchData}
                      className={'table table-striped table-bordereds '}
                      state={state}
                      setState={setState}
                      tableOptions={tableoptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContentContainer>

    </>
  )
}
export default Reports;
