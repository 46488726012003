import axios from 'axios';
import React, { useEffect, useState } from 'react';
import DataTable from "../../components/DataTable/DataTable";

import ContentContainer from '../../components/Main Content/ContentContainer';
import Filters from '../../components/Filters/Filters';
import 'react-toastify/dist/ReactToastify.css';
import { usePermissions } from '../../components/Authorization/Authorization';
import { useSelector } from 'react-redux';

const ActivityLogs = () => {
  const { permissions } = useSelector(state => state.permissions);
  const [searchQuery, setSearchQuery] = useState("")
  const [hasPermissions] = usePermissions();
  const [state, setState] = useState({
    rowsPerPage: 10,
    data: [],
    currentPage: 1,
    totalPages: 0,
  });

  const filterItems = [
    { table: "Statuses", column: "StatusId", label: "Status" },
    { table: "ActivityTypes", column: "ActivityTypeId", label: "Activity Type" },
  ]

  const [tableoptions, setTableoptions] = useState(
    [

      {
        column: 'name',
        appearAs: 'Activity Type',
        width: '120px',
        frozen: true,
        render: null,
      },
      {
        column: 'date',
        appearAs: 'Date',
        width: '230px',
        frozen: true,
        render: null,
      },
      {
        column: 'status',
        appearAs: 'Status',
        width: '100px',
        frozen: true,
        render: null
      },
      {
        column: 'description',
        appearAs: 'Description',
        width: '350px',
        frozen: false,
        render: null
      },
      {
        column: 'userAgent',
        appearAs: 'Browser/Operating system information',
        width: '800px',
        frozen: false,
        render: null
      }
    ])

  const fetchData = async () => {
    hasPermissions([38]) && await axios.post("activitylogs/GetActivityLogs", { page: state.currentPage, total: state.rowsPerPage, searchQuery })
      .then(res => { setState({ ...state, data: res.data.data, totalPages: res.data.totalPages }); })
      .catch((err) => { })
  }

  useEffect(() => { fetchData(); }, [permissions])
  return (
    <>
      <ContentContainer>
        <div class="row ">
          <div class="col-lg-12 my-3">
            <Filters table="ActivityLogs" filterItems={filterItems} onApply={() => { fetchData() }} />
          </div>
        </div>
        <section class=" zoom-xs-50 section animate__animated animate__fadeInLeft">
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card card-border-tertiary ">
                <div className='card-header border-0 mb-0 pb-0 d-flex justify-content-start'>
                  <h6 class='header-2  text-uppercase'>Activity Logs</h6>
                </div>
                <div className='card-body'>
                <div className='overflowx-auto'>
                    <DataTable
                      backendPagination
                      fetchData={() => fetchData()}
                      className={'table table-striped table-bordereds '}
                      state={state}
                      setState={setState}
                      tableOptions={tableoptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContentContainer>

    </>
  )
}
export default ActivityLogs;
