import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ContentContainer from '../../components/Main Content/ContentContainer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePermissions, useRoles } from '../../components/Authorization/Authorization';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import {  sanitize, successMessage } from '../../components/utils';

const AccessControl = () => {
  const { permissions } = useSelector(state => state.permissions);
  const [componentPermissionToUpdate, setComponentPermissionToUpdate] = useState([]);
  const [roleComponentToUpdate, setRoleComponentToUpdate] = useState([]);
  const [hasRoles] = useRoles();
  const [roleId, setRoleId] = useState(1);
  const [componentRoleMappings, setComponentRoleMappings] = useState([])
  const [componentPermissions, setComponentPermissions] = useState([])
  const [hasPermissions] = usePermissions();
  const onCheckNavigationManagement = (roleId, component, roleIndex, componentIndex) => {
    var temp = component;
    temp.checked = !component.checked;
    var mappingsCopy = componentRoleMappings;
    mappingsCopy[roleIndex].components[componentIndex] = temp
    setComponentRoleMappings([].concat(mappingsCopy));

    setRoleComponentToUpdate([{
      roleId: roleId,
      componentId: temp.id,
      checked: temp.checked
    }].concat(roleComponentToUpdate));
  }

  const onCheckPermissionsManagement = (componentId, permission, componentIndex, permissionIndex) => {
    var temp = permission;
    temp.isChecked = !permission.isChecked;
    var mappingsCopy = componentPermissions;
    mappingsCopy[componentIndex].permissions[permissionIndex] = temp
    setComponentPermissions([].concat(mappingsCopy));

    
    setComponentPermissionToUpdate([{
      roleId: roleId,
      componentId: componentId,
      permissionId: permission.id,
      checked: temp.isChecked
    }].concat(componentPermissionToUpdate));
  }

  const updateComponentPermissionMappings = async () => {
    if (hasPermissions([7])) {
      await axios.put("access-control/UpdateComponentPermissionMappings", componentPermissionToUpdate)
        .then(res => {
          fetchData();
          setComponentPermissionToUpdate([])
          Swal.fire(successMessage('Successful!'));
        })
        .catch(err => console.log(err.message))
    }
  }

  const updateComponentRoleMappings = async () => {
    await axios.put("access-control/UpdateComponentRoleMappings", roleComponentToUpdate)
      .then(res => {
        getComponentRoleMappings()
        setRoleComponentToUpdate([])
        Swal.fire(successMessage('Successful!'));
      })
      .catch(err => console.log(err.message))
  }

  const fetchData = async () => {
    if (hasPermissions([6])) {
      await axios.get("access-control/" + roleId)
        .then(res => { setComponentPermissions(res.data.data) })
        .catch(err => console.log(err.message))
    }
  }

  const getComponentRoleMappings = async () => {
    await axios.get("access-control/ComponentRoleMappings")
      .then(res => { setComponentRoleMappings(res.data.data) })
      .catch(err => console.log(err.message))
  }

  useEffect(() => { getComponentRoleMappings(); }, [permissions])
  useEffect(() => { fetchData() }, [roleId, permissions])
  return (
    <>
      <ToastContainer />
      <ContentContainer>

        <div className='row my-1'>
          <div className='col-12'>
            <div id="filters" class="px-3  bg-primary shadow  rounded-1 my-3">
              <div class="m-0 my-2 d-flex">
                <div class="dropdown d-flex ">
                  <p className='text-light  my-0 me-2 pt-1'> Role: </p>
                  <select className='role-select' value={roleId} onChange={(e) => setRoleId(e.target.value)} data-bs-toggle="dropdown" class="btn btn-sm  me-1 text-light border">
                    <option value={1}>Super Admin</option>
                    <option value={2}>Admin</option>
                    <option value={3}>Manager</option>
                    <option value={4}>Employee</option>
                  </select>
                </div>
                <button type="button" onClick={() => setRoleId(1)} class="btn btn-sm btn-secondary text-light ms-auto">Clear</button>
              </div>
            </div>
          </div>
        </div>

        {hasPermissions([6]) &&
          <section class="section mt-4">
            <div class="row">
              <div class="col-md-12 animate__animated animate__fadeInLeft">
                <div className='card'>
                  <div className='card-header border-0 mb-0 pb-0 d-flex'>
                    <h6 class='header-2 text-uppercase'>Role Permissions</h6>
                    {componentPermissionToUpdate.length != 0 && <button class="btn btn-sm btn-danger mx-2 ms-auto animate__animated animate__zoomIn" onClick={() => { fetchData(); setComponentPermissionToUpdate([]); }} type="button">Cancel</button>}
                    {componentPermissionToUpdate.length != 0 && <button class="btn btn-sm btn-primary animate__animated animate__zoomIn" onClick={() => { updateComponentPermissionMappings() }} type="button">Save Changes</button>}
                  </div>
                  <div class="card-body">
                    <div class="row ">
                      {componentPermissions.map((item, key) => (<>
                        <div key={item.id} class="col-md-3 d-flex flex-column mb-3">
                          <div className='header-2 mb-2'>{sanitize(item.name)}</div>

                          {item.permissions.map((element, index) => (
                            <div className=' d-flex '>
                              <input disabled={!hasPermissions([7])} key={element.id} onChange={() => { }} onClick={() => { onCheckPermissionsManagement(item.id, element, key, index) }} checked={element.isChecked} type="checkbox" />
                              <p class="p-0 m-0 ms-2">{sanitize(element.name)}</p>
                            </div>
                          ))}

                        </div>
                      </>))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>}

        {hasRoles([1]) && (<>
          <section class="section">
            <div class="row">
              <div class="col-md-12 animate__animated animate__fadeInLeft">
                <div className='card'>
                  <div className='card-header border-0 mb-0 pb-0 d-flex'>
                    <h6 class='header-2 text-uppercase'>Navigation Management</h6>
                    {roleComponentToUpdate.length != 0 && <button class="btn btn-sm btn-danger mx-2 ms-auto animate__animated animate__zoomIn" onClick={() => { getComponentRoleMappings(); setRoleComponentToUpdate([]); }} type="button">Cancel</button>}
                    {roleComponentToUpdate.length != 0 && <button class="btn btn-sm btn-primary animate__animated animate__zoomIn" onClick={() => { updateComponentRoleMappings() }} type="button">Save Changes</button>}
                  </div>
                  <div class="card-body">
                    <div class="row">
                      {componentRoleMappings.map((item, key) => (<>
                        <div key={item.id} class="col-md-3 d-flex flex-column">
                          <div className='header-2 mb-2'>{sanitize(item.name)}</div>
                          {item.components.map((element, index) => (
                            <div key={element.id} className=' d-flex '>
                              <input onChange={() => { }} onClick={() => { onCheckNavigationManagement(item.id, element, key, index) }} checked={element.checked} type="checkbox" />
                              <p class="p-0 m-0 ms-2">{sanitize(element.name)}</p>
                            </div>
                          ))}
                        </div>
                      </>))}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </>)}

      </ContentContainer>
    </>
  )
}
export default AccessControl;