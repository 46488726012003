import axios from 'axios';
const url="/localhost"
export const login = (formData) => dispatch => {
    dispatch({
        type: 'LOGIN_REQUEST',
        payload: formData
    });
    dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
            authenticated:true,
            roles:[1,3,4,3466,8]
        }
    })
    localStorage.setItem('userInfo', (JSON.stringify( {
        authenticated:true,
        roles:[1,3,4]
    })));
    window.location.reload(true);

    // axios.post(`${url}/api/login`, formData).
    //     then(res => {
    //         dispatch({
    //             type: 'LOGIN_SUCCESS',
    //             payload: res.data
    //         })
    //         localStorage.setItem('userInfo', (JSON.stringify(res.data)));
    //     }).catch(err => {
    //         dispatch({
    //             type: 'LOGIN_FAIL',
    //             error: err.response.message
    //         })
    //     })

}
export const logout = () => dispatch => {
    dispatch({
        type: 'LOGOUT_REQUEST'
    });
   
    dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
            authenticated:false,
            roles:[]
        }
    })
    localStorage.removeItem('userInfo');
    window.location.reload(true);
    // axios.post(`${url}/api/logout`).
    // then(res => {
    //     dispatch({
    //         type: 'LOGOUT_SUCCESS',
    //         payload: []
    //     })
    //     localStorage.removeItem('userInfo');
    // }).catch(err => {
    //     dispatch({
    //         type: 'LOGOUT_FAIL',
    //         error: err.response.message
    //     })
    // })
}