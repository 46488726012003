import Dashboard from './pages/Dashboard/Dashboard';
import SignIn from './pages/Authentication/SignIn';
import Profile from './pages/Account/Profile';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import Users from './pages/Users/Users';
import { useDispatch, useSelector } from 'react-redux';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import { Redirect } from './components/Redirect';
import AccessControl from './pages/AccessControl/AccessControl';
import { baseUrl } from './components/constants';
import axios from 'axios';
import ErrorPage from './pages/ErrorPage';
import UserForm from './pages/Account/UserForm';
import UserPasswordForm from './pages/Account/UserPasswordForm';
import Employees from './pages/Employees/Employees';
import Leaves from './pages/Leaves/Leaves';
import Holidays from './pages/Holidays/Holidays';
import LeaveTypes from './pages/LeaveTypes/LeaveTypes';
import { useEffect } from 'react';
import LoginTracker from './pages/LoginTracker/LoginTracker';
import EmailTemplates from './pages/EmailTemplates/EmailTemplates';
import Configurations from './pages/Configurations/Configurations';
import ActivityLogs from './pages/ActivityLogs/ActivityLogs';
import AuditTrail from './pages/AuditTrail/AuditTrail';
import EmailTemplatesForm from './pages/EmailTemplates/EmailTemplateForm';
import TempUserForm from './pages/Users/TempUserForm';
import TempLeaveForm from './pages/Leaves/TempLeaveForm';
import EmployeeProfile from './pages/Employees/EmployeeProfile';
import LeaveTypeForm from './pages/Configurations/LeaveTypeForm';
import Reports from './pages/Reports/Reports';


function App() {
  // const { token } = useSelector(state => state.userInfo.userInfo);
  // const dispatch = useDispatch();

  // const http = axios.create({
  //   baseURL: baseUrl,
  //   headers: {
  //     'X-Requested-With': 'XMLhttpRequest',
  //   },
  //   withCredentials: true
  // })
  
  // http.interceptors.request.use(
  //   async (config) => {
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );

  // http.interceptors.response.use(async (response) => {
  //   return response;
  // }, async function (error) {
  //   const originalRequest = error.config;
  //   // console.log(originalRequest)
  //   if (error.response.status === 401) { localStorage.removeItem('userInfo'); window.location.reload(true); }
  //   if (error.response.status === 408) { localStorage.removeItem('userInfo'); window.location.reload(true); }
  //   return Promise.reject(error);
  // });

  const { token } = useSelector(state => state.userInfo.userInfo);
  const dispatch = useDispatch();

  axios.defaults.baseURL = baseUrl;
  axios.interceptors.request.use(async config => {
    if (token) { config.headers["authorization"] = `Bearer ${token}`; }
    return config;
  },
    error => { Promise.reject(error) }
  );


  axios.interceptors.response.use(async (response) => {
    return response;
  }, async function (error) {
    const originalRequest = error.config;
    // console.log(originalRequest)
    if (error.response.status === 401) { localStorage.removeItem('userInfo'); window.location.reload(true); }
    if (error.response.status === 408) { localStorage.removeItem('userInfo'); window.location.reload(true); }
    return Promise.reject(error);
  });



  const GetCurrentUserDetails = (formData) => dispatch => {
    dispatch({
      type: 'USER_DETAILS_REQUEST',
      payload: formData
    });
    axios.get("User/GetCurrentUserDetails").
      then(res => {
        dispatch({
          type: 'USER_DETAILS_SUCCESS',
          payload: res.data.data
        })
      }).catch(err => {

      })

  }
  useEffect(() => {
   token && dispatch(GetCurrentUserDetails())
  }, [])

  const getUserPermissions = () => dispatch => {
    dispatch({
      type: 'FETCH_NAVIGATION_REQUEST',
    });
    axios.get("access-control/GetUserPermissions")
      .then(response => {
        dispatch({
          type: 'FETCH_PERMISSIONS_SUCCESS',
          payload: response.data.data
        })
      })
  }
  useEffect(() => {
  token &&  dispatch(getUserPermissions())
  }, [])


    return (
      <>

        <BrowserRouter>
          {token && <Sidebar />}
          <Routes >
            
            <Route exact path="/" element={<ProtectedRoutes element={<Dashboard />} />} />
            <Route exact path="/profile" element={<ProtectedRoutes element={<Profile />} />} />
            <Route exact path="/profile/update" element={<ProtectedRoutes element={<UserForm />} />} />
            <Route exact path="/profile/security" element={<ProtectedRoutes element={<UserPasswordForm />} />} />
            <Route path="/users/create" element={<ProtectedRoutes element={<TempUserForm />} />} />
            <Route path="/users/update/:id" element={<ProtectedRoutes element={<TempUserForm />} />} />
            <Route path="/users" element={<ProtectedRoutes element={<Users />} />} />
            <Route path="/leaves" element={<ProtectedRoutes element={<Leaves />} />} />

            <Route path="/leaves/create" element={<ProtectedRoutes element={<TempLeaveForm />} />} />
            <Route path="/leaves/:id" element={<ProtectedRoutes element={<TempLeaveForm />} />} />
            <Route path="/employees" element={<ProtectedRoutes element={<Employees />} />} />
            <Route path="/employees/:id" element={<ProtectedRoutes element={<EmployeeProfile />} />} />
            <Route path="/employees/:employeeId/leaves/create" element={<ProtectedRoutes element={<TempLeaveForm />} />} />

            <Route path="/accessControl" element={<ProtectedRoutes element={<AccessControl />} />} />
            <Route path="/accessControl" element={<ProtectedRoutes element={<AccessControl />} />} />

            <Route path="/holidays" element={<ProtectedRoutes element={<Holidays />} />} />
            <Route path="/leave-types" element={<ProtectedRoutes element={<LeaveTypes />} />} />
            <Route path="/login-tracker" element={<ProtectedRoutes element={< LoginTracker />} />} />
            <Route path="/activity-logs" element={<ProtectedRoutes element={< ActivityLogs />} />} />
            <Route path="/email-management" element={<ProtectedRoutes element={< EmailTemplates />} />} />

            <Route path="/configurations/leave-types/:id" element={<ProtectedRoutes element={< LeaveTypeForm />} />} />
            <Route path="/configurations" element={<ProtectedRoutes element={< Configurations />} />} />
            <Route path="/audit-trail" element={<ProtectedRoutes element={< AuditTrail />} />} />
            <Route path="/email-templates/:id" element={<ProtectedRoutes element={< EmailTemplatesForm />} />} />
            <Route path="/reports" element={<ProtectedRoutes element={< Reports />} />} />

            
          </Routes>
          <Routes>
            <Route path="/signin" element={<Redirect element={< SignIn />} />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      </>
    );

}

export default App;
